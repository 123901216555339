body.node-type-news{

	.node-news{
		padding-top: 114px;
		padding-bottom: 54px;
		.field-name-field-image{
			float: left;
			margin: 6px 57px 0px 0;
			border-bottom: 3px solid #2dc0f6;
			@include rt('medium'){
				border: none;
				float: none;
				margin: 6px 0 20px 0;
				text-align: center;
				.field-item {
					border-bottom: 3px solid #2dc0f6;
					display: inline-block;
				}
			}
			@include rt('small'){
				float: none;
				margin: 6px 0 40px 0;
			}
			img{
				@include rt('medium'){
				}
				@include rt('small'){
					max-width: 100%;
					height: auto;
					display: block;
					margin: 0 auto;
				}
			}
		}
		.node-title{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 12px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin: 0 0 34px 0;
			@include rt('medium'){
				text-align: center;
			}
		}
		.field-name-body{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.9;
			@include rt('medium'){
				text-align: center;
			}
			p{
				margin: 0 0 62px 0;
			}
			blockquote{
				border-left: 41px solid #2dc0f6;
				color: #051a3b;
				font-family: Georgia;
				font-size: 17px;
				font-style: italic;
				padding: 6px 0 10px 24px;
				margin: -5px 0 36px 13px;
				line-height: 1.888;
				@include rt('medium'){
					border-left: 31px solid #2dc0f6;
				}
				@include rt('small'){
					border-left: 10px solid #2dc0f6;
					padding: 6px 0 10px 10px;
					margin: -5px 0 36px 0px;
				}
				p{
					margin: 0;
				}
			}
			h6{
				font-family: $OpenSans;
				font-size: 15px;
				color: #051a3b;
				font-weight: 700;
				margin: 0 0 6px 0;
			}
			ul{
				margin: 0 0 28px 0px;
				padding: 0;
				li{
					list-style: none;
					position: relative;
					padding: 0 0 0 0px;
					&:before{
						content: '•';
						font-size: 15px;
						color: #ffab30;
						padding: 0 3px 0 0;
					}
				}
			}
		}
	}

}

body.page-news{

	#main-wrapper{
		background-color: #edf4fa;
		padding-top: 83px;
		padding-bottom: 107px;
	}

	.page-header{
		margin: 0;
		padding: 0px 0 0 0;
		text-align: center;
		h1{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 30px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 6px;
		}
	}

	.view-news{
		margin: 55px 0 0 0;
		.view-content{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			align-items:flex-start;
			justify-content:space-between;
			@include rt('medium'){
				justify-content:space-around;
			}
			@include rt('small'){
				flex-direction:column;
				align-items:center;
				justify-content:flex-start;
			}
			.views-row{
				width: 363px;
				height: 600px;
				background-color: #ffffff;
				margin: 0 0 40px 0;
				@include rt('small'){
					width: 100%;
					max-width: 363px;
				}
				.node-news{
					.content{
						.fieldset{
							height: 400px;
							display: flex;
							flex-direction:column;
							padding: 55px 0 65px 0;
						}
						.field-name-field-image{
							border-bottom: 3px solid #2dc0f6;
							img{
								@include rt('small'){
									max-width: 100%;
									height: auto;
								}
							}
						}
						.node-title{
							margin: 0px 0 0 0;
							text-align: center;
							padding: 0 15px;
							a{
								color: #051a3b;
								font-family: $OpenSans;
								font-size: 12px;
								font-weight: 900;
								text-transform: uppercase;
								letter-spacing: 1px;
							}
						}
						.field-name-body{
							text-align: center;
							color: #051a3b;
							font-family: $OpenSans;
							font-size: 15px;
							font-weight: 300;
							margin: 50px 0 0 0;
							padding: 0px 52px;
							line-height: 2;
							@include rt('small'){
								padding: 0 10px;
							}
							p{
								margin: 0;
							}
						}
						.node-detail{
							margin: auto 0 0 0;
							a{
								display: flex;
								flex-direction:row;
								align-items:center;
								justify-content:center;
								width: 220px;
								height: 40px;
								margin: 0 auto 0 auto;
								border: 1px solid rgba(5,26,59,.3);
								border-radius: 20px;
								color: #767b88;
								font-family: $OpenSans;
								font-size: 12px;
								font-weight: 900;
								text-transform: uppercase;
								text-decoration: none;
								letter-spacing: 1px;
								&:hover{
									background-color: #2dc0f6;
									border-color: #2dc0f6;
									color: white;
								}
							}
						}
					}
				}
			}
		}

		ul.pagination{
			padding: 0;
			margin: 41px 0;
			.pager-last,
			.pager-first{
				display: none;
			}
			.pager-item{
				>*{
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					color: #051a3b;
					padding: 6px 6px;
					&:hover{
						color: #2dc0f6;
					}
				}
			}

			.pager-current{
				>*{
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					color: #2dc0f6;
					padding: 6px 6px;
				}
			}

			.pager-previous,
			.pager-next{
				> *{
					color: #051a3b;
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-decoration: underline;
					text-transform: uppercase;
					padding: 6px 15px;
					&:hover{
						color: #2dc0f6;
						text-decoration: underline;
					}
				}
			}
		}

	}

	.pane-custom{
		margin: 59px 0 0 0;
		color: #051a3b;
		font-family: $OpenSans;
		font-size: 15px;
		font-weight: 300;
		line-height: 1.88;
	}

}