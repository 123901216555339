/*#footer{
	background: #01102f;
	margin:0;
}
.second_menu{
	background: #47546c;
	nav.navbar{
		margin: 0;
		min-height: auto;
		padding: 20px 0;
	}
	#navbar-collapse-footer{
		position: relative;
		z-index: 100;
		&>ul>li{
			float: left;
			position: relative;
			background: #47546c;

			a{
				color: #fff;
				font-size: 11px;
				text-transform: uppercase;
				&:hover,&.active{
					color: #2dc0f6;
				}
			}
			ul{
				position: absolute;
				// display: none;
				background: #47546c;
				margin-left: 0;
				min-width: 300px;
				border-radius: 5px;
				padding: 30px 0 30px 40px;
				left: -40px;
				li{
					&:before{
						content: "•";
						color: #2dc0f6;
						float: left;
					}
				}
			}
			&:hover{
				ul{
					display: block;
				}
			}
		}
	}
}*/
footer#footer {
	margin: 0px;
	.popup-buttons {
		position: relative;
	}
	.footer-social-links-block {
		left: 8px;
		position: absolute;
		top: 143px;
		.block__content {
			a {
				display: block;
				font-size: 0;
				height: 36px;
				margin-bottom: 11px;
				text-indent: -99999px;
				width: 36px;
			}
			.footer-facebook {
				background: url(../images/footer_facebook.png) center no-repeat;
			}
			.footer-youtube {
				background: url(../images/footer_youtube.png) center no-repeat;
			}
		}
	}
	.bottom-part {
		background: #1b2844;
		ul.menu {
			li {
				a {
					line-height: 80px;
				}
				&:hover {
					ul {
						bottom: 80px;
						top: auto;
						border-radius: 5px 5px 0px 0px;
					}
				}
			}
		}
	}
	.top-part {
		padding-top: 50px;
		padding-bottom: 75px;
		background: #01102f;
	}
	.big-block {
		padding-left: 113px;
		#main-menu {
			display: flex;
			li {
				&.expanded:hover {
					& > a {
						&:before {
							border-radius: 0px 0px 0px 5px;
						}
						&:after {
							border-radius: 0px 0px 5px 0px;
						}
					}
					ul {
						top: auto;
						bottom: 29px;
						box-shadow: 0 -6px 12px rgba(0,0,0,0.175);
					}
				}
			}
		}
		.under-top-menu {
			justify-content: flex-end;
			.region-phone-header {
				.region-phone-header {
					background: none;
					padding-left: 0px;
				}
			}
		}
	}
	.region-address-copirate,
	.region-order-develop {
		font-size: 15px;
		line-height: 30px;
		color: white;
		font-weight: 400;
		clear: both;
		display: flex;
    	justify-content: space-between;
	}
	.region-address-copirate {
		padding-top: 34px;
		span {
			font-weight: 800;
			padding: 0px 8px;
		}
	}
	.region-order-develop  {
		padding-top: 28px;
		a {
			color: white;
			font-size: 15px;
			line-height: 30px;
			font-weight: 800;
			text-decoration: underline;
		}
	}
}
.map_wrap
{
	position: relative;
	.region-contacts {
		/*position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;*/
		@include rt('medium'){
			display: none;
		}
		@include rt('small'){
			display: none;
		}
		.block-block {
			/*position: relative;
			@include make-container();*/
			position: static;
		}
		.block__content {
			background: url('../images/contact_info_bg.png ');
			width: 433px;
			height: 420px;
			position: absolute;
		    /*left: -40px;*/
		    left: calc( ((100% - 1200px) / 2) - 40px );
		    top: 102px;
		    /*position: relative;*/
		    color: #051a3b;
			font-size: 15px;
			font-weight: 300;
			line-height: 30px;
			text-align: center;
			padding-top: 58px;
			padding-left: 89px;
			padding-right: 78px;
			strong {
				font-weight: 800px;
			}
			p {
				margin-bottom: 32px;
			}
		}
	}
}