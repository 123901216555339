.page-contact,
.page-kontaktihtml{
	.page-header {
		h1 {
			color: #051a3b;
			font-size: 30px;
			font-weight: 300;
			text-transform: uppercase;
			text-align: center;
			padding-top: 84px;
    		letter-spacing: 6px;
    		font-family: "Open Sans";
		}
	}
	#panel-bootstrap-row-main{
		color: #051a3b;
		font-size: 15px;
		@include rt('medium'){
			text-align: center;
		}
		@include rt('small'){
			text-align: center;
		}
		#panel-bootstrap-column-1,
		#panel-bootstrap-column-2{
			@include rt('small'){
				padding-top: 30px;
				clear: both;
			}
		}
		#panel-bootstrap-column-2{
			@include rt('medium'){
				padding-top: 30px;
				clear: both;
			}
		}
		#panel-bootstrap-column-main-column,#panel-bootstrap-column-1,#panel-bootstrap-column-2
		{
			.pane-content{
			    margin-left: 90px;
    			margin-top: 17px;  			
			}
			img{
			}
			.img_wrap {
				display: block;
				min-height: 77px;
				margin-bottom: 25px;
			}
			p{
				line-height: 1;
				margin-bottom: 11px;
			}

		}
		#panel-bootstrap-column-main-column{
			border-right: 1px solid #2dc0f6;
			/*.pane-content{
			    padding-left: 5px;
				padding-top: 8px;

			}*/
		}
		#panel-bootstrap-column-1{
			border-right: 1px solid #2dc0f6;
			/*.pane-content{
			    margin-left: 90px;
    			margin-top: 17px;  			
			}*/
		}
		/*#panel-bootstrap-column-2{
			.pane-content{
		        margin-left: 49px;
   				margin-top: 5px;
			}
		}*/
		@include rt(sm) {
			#panel-bootstrap-column-main-column,#panel-bootstrap-column-1,#panel-bootstrap-column-2
			{
				border-right: 0;
				.pane-content{
					padding: 0;
					margin:0;
				}
			}
		}
	}
	.region-content {
		padding-top: 53px;
		padding-bottom: 122px;
	}
}