.node-type-behavior-rules {
	h1 {
		color: #ffab30;
		font-size: 30px;
		font-weight: 300;
		text-transform: uppercase;
		border-bottom: 3px solid #edf4fa;
		font-family: "Open Sans";
		padding-top: 63px;
		letter-spacing: 6px;
		padding-bottom: 15px;
		margin-bottom: 61px;
		@include make-container();
		@include rt('small'){
			font-size: 24px;
			text-align: center;
		}
	}
	.field-name-field-br-top {
		@include make-container();
		color: #051a3b;		
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
		padding-bottom: 75px;
		@include rt('small'){
			font-size: 15px;
		}
		strong {
			font-size: 15px;
			@include rt('small'){
				font-size: 14px;
			}
		}
		p {
			margin-bottom: 16px;
		}
		ul {
			list-style: none;
			padding-left: 0px;
			padding-top: 2px;
			li {
				font-size: 16px;
				font-weight: 400;
				line-height: 30px;
				color: #051a3b;
				&:before {
					content: '•';
					color: #2dc0f6;
					padding-right: 4px;
				}
			}
		}
	}
	.field-name-field-br-text-bg {
		background: #edf4fa;
		.field-items {
			@include make-container();
			color: #051a3b;		
			font-size: 16px;
			font-weight: 300;
			line-height: 30px;
			padding-top: 81px;
			padding-bottom: 88px;
			@include rt('small'){
				font-size: 15px;
			}
			img {
				margin-right: 59px;
				@include rt('medium'){
				}
				@include rt('small'){
					margin-right: 0px;
					max-width: 100%;
					height: auto;
				}
			}
			strong {
				font-size: 15px;
				@include rt('small'){
					font-size: 14px;
				}
			}
			p {
				margin-bottom: 16px;
			}
		}
	}
	.field-name-field-br-bottom {
		@include make-container();
		color: #051a3b;		
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
		padding-top: 81px;
		padding-bottom: 70px;
		@include rt('small'){
			font-size: 15px;
		}
		img {
			margin-right: 59px;
		}
		strong {
			font-size: 15px;
			@include rt('small'){
				font-size: 14px;
			}
		}
		p {
			margin-bottom: 16px;
		}
	}
	.bottom_img {
		position: relative;
		.field-name-field-br-img {
			.background-image-formatter {
				height: 423px;
				background-position: center;
				background-size: cover;
			}
		}
		.field-name-field-br-img-text {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		    text-align: center;
			display: flex;
		    justify-content: center;
		    align-items:center;
			.field-items {
				@include make-container();
				@include rt('small'){
					width: 100%;
				}
				.field-item {
					padding: 0px 225px;
					//padding-top: 119px;
					@include rt('medium'){
						padding: 0 49px;
					}
					@include rt('small'){
						padding: 0;
					}
				}
				&:before, &:after {
					display: none;
				}
				.orange {
					color: #ffab30;
					font-size: 24px;
					font-weight: 300;
					text-transform: uppercase;
					line-height: 30px;
					@include rt('small'){
						line-height: 24px;
						word-break: break-all;
						font-size: 18px;
					}
				}
				.medium {
					color: white;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 30px;
					line-height: 30px;
					padding-top: 27px;
					@include rt('small'){
						font-size: 22px;
					}
				}
				.big {
					color: white;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 50px;
					line-height: 76px;
					@include rt('small'){
						font-size: 32px;
						line-height: 50px;
					}
				}
			}
		}
	}
}