.about-mini-panel{
	background: url(../images/bg_about.png) no-repeat center top;
	height: 553px;
	@include rt('medium'){
		height: auto;
		background-size: cover;
		padding-bottom: 15px;
	}
	@include rt('small'){
		height: auto;
		background-size: cover;
		padding-bottom: 15px;
	}
	#panel-bootstrap-region-center{
		.orange{
			letter-spacing: 6px;
		}
		.namber{
			margin-top: -7px;
    		letter-spacing: 14px;
		}
	}
	.pane-content .title{
		margin-top: 130px;
	}
	.pane-content,.pane-title{
		@extend .container;
		color: #fff;
		text-transform: uppercase;

		.orange{
			// word-spacing: 3;
			color: #ffab30;
			font-size: 30px;
			text-align: center;
		}
		.namber{
			text-align: center;
			color: #fff;
			font-size: 20px;
		}
	}

	#panel-bootstrap-region-content{
		display: flex;
		margin-top: 70px;
		justify-content: space-between;
		@include rt('medium'){
			flex-wrap:wrap;
		}
		@include rt('small'){
			flex-wrap:wrap;
			justify-content: center;
		}
		& > div{
			// display: inline-block;
			width: 160px;
			text-align: center;
			@include rt('medium') {
				margin-bottom: 25px;
				width: 30%;
			}
			@include rt('small') {
				margin-bottom: 25px;
				width: 200px;
				.paragraphs-item-about-the-sanatorium {
					padding: 0px 10px;
				}
			}
			.field-name-field-namber{
				font-size: 50px;
				color: #ffab30;
				line-height: 0.8;
				&:after{
					content: " ";
					width: 45px;
					height: 8px;
					display: inline-block;
					border-bottom: 8px solid #fff;
				}
			}
			.field-name-field-description{
				margin-top: 25px;
			}
		}
	}
}