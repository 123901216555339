body.node-type-m-conference{

	.big-image{
		position: relative;
		@include rt('medium'){
		}
		@include rt('small'){
			padding: 25px 0;
		}
		.field-name-field-image{
			position: relative;
			@include rt('medium'){
			}
			@include rt('small'){
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(1,16,47,.5);
			}
			.field-item{
				@include rt('small'){
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(1,16,47,.5);
					}
				}
			}
			img{
				max-width: 100%;
				@include rt('medium'){
				}
				@include rt('small'){
					width: 100%;
					height: 100%;
					object-fit:cover;
				}
			}
		}
		.bi-container{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			@include rt('medium'){
			}
			@include rt('small'){
				position: relative;
			}
			.field-name-field-mc-formatted-title{
				font-family: $OpenSans;
				font-size: 30px;
				font-weight: 300;
				text-transform: uppercase;
				color: white;
				text-align: center;
				letter-spacing: 6px;
				margin: 118px 0 0 0;
				@include rt('small'){
					margin: 50px 0 0 0;
					font-size: 24px;
				}
				span{
					color: #ffab30;
				}
			}
			.field-name-field-mc-short-desc{
				margin: 108px 0 0 0;
			    width: 680px;
			    float: left;
			    @include rt('medium'){
			    }
			    @include rt('small'){
			    	width: 100%;
			    	float: none;
			    	margin: 30px 0 0 0;
			    }
				.field-label{
					color: white;
					font-family: $OpenSans;
					font-size: 15px;
					font-weight: 700;
				}
				.field-items{
					margin: 22px 0 0 0;
					line-height: 1.92;
					color: white;
					font-family: $OpenSans;
					font-size: 15px;
					font-weight: 300;
				}
			}
			.price-area{
				margin: 64px 0 0 0;
				width: 413px;
				height: 308px;
				background: url('../images/conference/bg-price-confa.png') center no-repeat;
				float: right;
				padding: 0 0 0 76px;
				@include rt('medium'){
				}
				@include rt('small'){
					margin-left: calc((100% - 413px) / 2);
					float: none;
				}
				.field-name-field-mc-price{
					color: white;
					font-family: $OpenSans;
					font-size: 32px;
					text-transform: uppercase;
					font-weight: 700;
					float: left;
					margin: 49px 0 0 0px;
					word-spacing: 5px;
					sup{
						text-transform: lowercase;
						padding: 0 0 0 14px;
					}
				}
				.field-name-field-mc-time-unit{
					margin: 48px 0 0 10px;
					float: left;
					color: white;
					font-family: $OpenSans;
					font-size: 32px;
					text-transform: uppercase;
					font-weight: 300;
					letter-spacing: 1px;
					.field-item{
						&:before{
							content: '/';
							padding: 0 12px 0 0;
						}
					}
				}
				.field-name-field-mc-general-area{
					@include clearfix();
					clear: both;
					color: white;
					font-family: $OpenSans;
					font-size: 14px;
					text-transform: uppercase;
					padding: 16px 0 0 0;
					.field-label{
						font-weight: 900;
						letter-spacing: 1px;
					}
					.field-items{
						font-weight: 400;
						padding-left: 5px;
						span{
							padding: 0 0 0 7px;
						}
					}
				}
				.field-name-field-mc-capacity{
					color: white;
					font-family: $OpenSans;
					font-size: 14px;
					text-transform: uppercase;
					.field-label{
						font-weight: 900;
						letter-spacing: 1px;
					}
					.field-items{
						font-weight: 400;
						padding-left: 5px;
						span{
							padding: 0 0 0 7px;
						}
					}
				}
				.bulletproof{
					display: flex;
					flex-direction:row;
					align-items:center;
					justify-content:center;
					width: 180px;
					height: 40px;
					border: 3px solid white;
					border-radius: 20px;
					margin: 31px 0 0 0px;
					a{
						color: white;
						font-family: $OpenSans;
						font-size: 11px;
						font-weight: 900;
						text-transform: uppercase;
						letter-spacing: 1px;
						text-decoration: none;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.img-gallery{
		padding: 32px 0 29px 0;
		@include rt('medium'){
			margin-top: 190px;
		}
		.paragraphs-items-field-mc-gallery{
			> .field-name-field-mc-gallery{
				> .field-items{
					&.slick-slider{
						padding: 28px;
					}
					.slick-track{
						display: flex;
						flex-direction:row;
						align-items:center;
						img{
							max-width: 100%;
							height: auto;
							margin: auto;
						}
					}
					.slick-slide{
						height: initial;
					}
					.slick-prev{
						left: 0;
						background: url(../images/blue_arrow_left.png) center no-repeat;
					}
					.slick-next{
						right: 0;
						background: url(../images/blue_arrow_right.png) center no-repeat;
					}
					.slick-arrow{
						position: absolute;
						top: 50%;
						transform:translateY(-50%);
						border: none;
						height: 27px;
						outline: none;
						text-indent: -99999px;
						width: 14px;
						opacity: .5;
						&:hover{
							opacity: 1;
						}
					}
					> .field-item{
						float: left;
						margin-bottom: 1px;
						.paragraphs-item-pb-image-image-style{
							a{
								display: block;
								position: relative;
								&:hover{
									&:before{
										content: '';
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										background: rgba(45,192,246,.9) url('../images/search.png') center no-repeat;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.equip-and-body{
		background-color: #edf4fa;
		padding: 103px 0 115px 0;
		.field-name-field-mc-hall-equip{
			.field-label{
				color: #051a3b;
				font-family: $OpenSans;
				font-size: 30px;
				font-weight: 300;
				text-transform: uppercase;
				text-align: center;
				letter-spacing: 5px;
			}
			> .field-items{
				display: flex;
				flex-direction:row;
				flex-wrap:wrap;
				margin: 64px 0 0 0;
				@include rt('medium'){
					justify-content: center;
				}
				> .field-item{
					margin-bottom: 67px;
					width: 25%;
					@include rt('medium'){
						width: calc(100% / 3);
					}
					@include rt('small'){
						width: 100%;
					}
					.taxonomy-term{
						display: flex;
						flex-direction:column;
						align-items:center;
						.field-name-field-image{
							position: relative;
							height: 75px;
							&:after{
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								margin: 0 auto;
								width: 42px;
								height: 8px;
								background-color: #cad7e3;
							}
						}
						.taxonomy-title{
							color: #051a3b;
							font-family: $OpenSans;
							font-size: 11px;
							font-weight: 700;
							text-transform: uppercase;
							margin: 25px 0 0 0;
						}
					}
				}
			}
		}
		.field-name-body{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 15px;
			font-weight: 300;
			margin: 43px 0 0 38px;
			@include rt('small'){
				margin-left: 0px;
				font-size: 14px;
			}
			p{
				margin: 0 0 0 0;
				&:not(:last-child){
					margin: 0 0 29px 0;
				}
			}
		}
	}

	.webform-bulletproof{
		padding: 115px 0 123px 0;
		.webform-client-form{
			> div{
				@include clearfix();
				> .form-item{
					margin: 0 0 40px 0;
				}
			}
		}
		.webform-component--zagolovok{
			text-align: center;
			line-height: 1;
			margin: 0 0 47px 0!important;
			.t1{
				color: #ffab30;
				font-family: $OpenSans;
				font-size: 30px;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: 7px;
				@include rt('small'){
					font-size: 24px;
					letter-spacing: 5px;
				}
			}
			.t2{
				color: #01102f;
				font-family: $OpenSans;
				font-size: 20px;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: 16px;
				margin: 3px 0 0 0px;
				@include rt('small'){
					font-size: 16px;
					letter-spacing: 12px;
				}
			}
		}
		select,
		input[type="text"],
		input[type="email"]{
			width: 363px;
			height: 60px;
			@include rt('medium'){
				width: 100%;
			}
			@include rt('small'){
				width: 100%;
			}
		}
		textarea{
			width: 100%;
			height: 120px;
			resize:none;
			border: none;
			box-shadow: none;
		}

		.webform-component--tip-lica,
		.webform-component--e-mail,
		.webform-component--fio,
		.webform-component--data-nachala,
		.webform-component--kontaktnyy-telefon,
		.webform-component--data-okonchaniya{
			width: calc(100% / 3);
			float: left;
			@include rt('medium'){
				float: none;
				width: 100%;
			}
			@include rt('small'){
				float: none;
				width: 100%;
			}
		}

		.webform-component--fio,
		.webform-component--data-nachala{
			input{
				margin-left: auto;
				margin-right: auto;
			}
		}
		.webform-component--kontaktnyy-telefon,
		.webform-component--data-okonchaniya{
			input{
				margin-left: auto;
			}
		}
		.webform-component--dopolnitelnye-pozhelaniya{
			clear: both;
		}

		.webform-component--liqpay-i-rekvizity{
			float: right;
			display: flex;
			flex-direction:row;
			margin: 0 249px 0 0!important;
			@include rt('medium'){
			  float: none;
			  margin: 0 0 0 0!important;
			  justify-content: center;
			}
			@include rt('small'){
			  float: none;
			  margin: 0 0 0 0!important;
			  flex-direction:column;
			  justify-content: flex-start;
			  align-items:center;
			}
			.liqpay{
				a{
					display: block;
					margin: 0 39px 0 0;
					padding: 14px 0 0 0;
					letter-spacing: 1px;
					width: 240px;
					height: 80px;
					border-radius: 40px;
					box-shadow: 0 4px 0 #d8dee3;
					text-align: center;
					background: #edf4fa url('../images/conference/liqpay.jpg') bottom 17px center no-repeat;
					color: #71af2a;
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					@include rt('small'){
					  margin: 0 0px 40px 0;
					}
				}
			}
			.requisites{
				height: 80px;
				display: flex;
				flex-direction:row;
				align-items:center;
				a{
					color: #ffab30;
					font-family: $OpenSans;
					font-size: 11px;
					font-weight: 700;
					text-decoration: underline;
					text-transform: uppercase;
				}
			}
		}

		.form-actions{
			margin: 0 0 0 246px;
			float: left;
			@include rt('medium'){
			  margin: 40px 0 0 0;
			  float: none;
			  text-align: center;
			}
			@include rt('small'){
			  margin: 40px 0 0 0;
			  float: none;
			  text-align: center;
			}
			input[type="submit"]{
				border:0;
				width: 240px;
				height: 80px;
				padding: 0;
				background-color: #ffab30;
				border-radius: 40px;
				box-shadow: 0 4px 0 #e0982b;
				color: white;
				font-family: $OpenSans;
				font-size: 12px;
				font-weight: 900;
				text-transform: uppercase;
				letter-spacing: 1px;
				@include rt('medium'){
				}
				@include rt('small'){
				  display: inline-block;
				}
			}
		}

	}

	.epilogue{
		background-color: #edf4fa;
		padding: 99px 0 118px 0;
		.field-name-field-mc-epilogue{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.92;
		}
	}

}

body.page-conference{

	.conference-service{
		.pane-content{
			position: relative;
			.cs-img{
				position: relative;
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(1,16,47,.6);
				}
				img{
					@include rt('small'){
						height: 140px;
					    width: 100%;
					    object-fit: cover;
					    object-position: center;
					}
				}
			}
			.cs-container{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				@include rt('small'){
					position: relative;
				}
				.cs-title{
					color: #ffab30;
					font-family: $OpenSans;
					font-size: 30px;
					font-weight: 300;
					text-transform: uppercase;
					text-align: center;
					letter-spacing: 6px;
					margin: 138px 0 0 0;
					@include rt('small'){
						margin: 50px 0 50px 0;
						font-size: 24px;
						letter-spacing: 4px;
					}
				}
				.cs-desc{
					color: white;
					font-family: $OpenSans;
					font-size: 15px;
					font-weight: 300;
					text-align: center;
					margin: 12px 0 0 0px;
					padding: 0 100px;
					line-height: 1.92;
					@include rt('small'){
						font-size: 14px;
						padding: 0px;
						color: #051a3b;
					}
				}
			}
		}
	}

	.view-v-conference{
		margin: 91px 0 0 0;
		.view-content{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:space-between;
			@include rt('medium'){
				flex-direction:column;
				justify-content:flex-start;
				align-items:center;
			}
			@include rt('small'){
				flex-direction:column;
				justify-content:flex-start;
				align-items:center;
			}
			.views-row{
				width: 565px;
				min-height: 473px;
				margin-bottom: 90px;
				@include rt('small'){
					width: 100%;
					max-width: 565px;
				}
				&:last-child{
					margin-left: auto;
					margin-right: auto;
				}
				.node-m-conference{
					.big-image{
						position: relative;
						.field-name-field-image{
							position: relative;
							&:before{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background: bottom center url('../images/conference/bg-confa-dark-bot.png') no-repeat;
							}
							img{
								@include rt('small'){
									max-width: 100%;
									height: auto;
								}
							}
						}
						.bi-container{
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							.node-title{
								text-align: center;
								margin: 0 0 18px 0;
								div,
								a {
									color: white;
									font-family: $OpenSans;
									font-size: 20px;
									font-weight: 700;
									@include rt('small'){
										font-size: 16px;
									}
									&:hover{
										color: #ffab30;
										text-decoration: none;
									}
								}
							}
						}
					}
					.field-name-field-mc-short-desc{
						margin: 32px 0 0 0;
						line-height: 1.92;
						text-align: center;
						color: #051a3b;
						font-family: "Open Sans";
						font-size: 15px;
						font-weight: 300;
						@include rt('small'){
							font-size: 14px;
						}
					}
					.node-detail{
						a{
							margin: 32px auto 0 auto;
							width: 180px;
							height: 40px;
							background-color: #2dc0f6;
							border-radius: 20px;
							display: flex;
							align-items:center;
							justify-content:center;
							color: white;
							font-family: $OpenSans;
							font-size: 12px;
							font-weight: 700;
							text-transform: uppercase;
							letter-spacing: 1px;
							&:hover{
								background-color: #ffab30;
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}

	.conference-footer{
		background-color: #edf4fa;
		margin: 30px 0 0 0;
		padding: 111px 0 82px 0;
		.pane-content{
			@include make-container();
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.92;
			@include rt('small'){
				font-size: 14px;
			}
			p{
				margin: 0 0 34px 0;
			}
			h6{
				color: #051a3b;
				font-family: $OpenSans;
				font-weight: 700;
				margin: 0 0 6px 0;
				font-size: 15px;
				@include rt('small'){
					font-size: 14px;
				}
			}
			ul{
				margin: 0 0 28px 0px;
				padding: 0;
				li{
					list-style: none;
					position: relative;
					padding: 0 0 0 0px;
					&:before{
						content: '•';
						font-size: 15px;
						color: #ffab30;
						padding: 0 3px 0 0;
					}
				}
			}
		}
	}

}