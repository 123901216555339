.page-questions-and-answers,
.page-voprosi-i-otvetihtml{
	background: #edf4fa;
	.page-header {
		border-bottom: 3px solid #fff;
		padding-top: 86px;
		h1{
			color: #051a3b;
			font-family: "Open Sans";
			font-size: 30px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 6px;
			@include rt('small'){
				font-size: 24px;
			}
		}
	}
	#content{
		position: relative;
		.view-header,.view-filters{
			position: absolute;
			right: 0;
			top: -60px;
			@include rt('medium'){
				position: relative;
				top: 0;
			}
			@include rt('small'){
				position: relative;
				top: 0;
			}
		}
		.view-header{
			@include rt('medium'){
				text-align: center;
				margin: 0 0 20px 0;
			}
			@include rt('small'){
				text-align: center;
				margin: 0 0 20px 0;
			}
		}
		.view-filters{
			right: 214px;
			@include rt('medium'){
				right: 0;
			}
			@include rt('small'){
				right: 0;
			}
			select.form-control{
				width: 363px;
				height: 30px;
				padding-bottom: 1px;
    			padding-top: 0;
    			@include rt('medium'){
    				width: 100%;
    				max-width: 363px;
    				margin: 0 auto;
    			}
    			@include rt('small'){
    				width: 100%;
    				max-width: 363px;
    				margin: 0 auto;
    			}
			}
			#edit-field-direction-tid-i18n-wrapper{
				@include rt('medium'){
					float: none;
				}
				@include rt('small'){
					float: none;
				}
			}
		}
		.views-exposed-form .views-exposed-widget{
			padding: 0;
		}
		.views-row{
			border-bottom: 1px solid #bec6d1;
			padding-bottom: 60px;
			ul {
				list-style: none;
				padding-left: 0px;
				padding-top: 2px;
				li:before {
				    content: '•';
				    color: #2dc0f6;
				    padding-right: 4px;
				    font-size: 16px;
				}
			}
		}
	}
	.orange{
		background-color: #ffab30;
		border-radius: 15px;
		color: #fff;
		font-size: 12px;
		font-weight: 700;
		line-height: 28px;
		outline: none;
		text-transform: uppercase;
		padding: 0 38px;
		letter-spacing: 1px;
	}
	#main{
		@extend .container;
		article{
			padding-top: 20px;
		}
		.name_wrapp{
			float: left;
			padding-right: 10px;
		}
		.name_wrapp,.field-name-body,.field-name-field-answer{
			font-weight: 700;
			text-transform: uppercase;
			color: #051a3b;
			font-size: 15px;
			line-height: 28px;
		}
		.field-name-body{
			font-weight: 300;
			text-transform: none;
		}
		.field-name-field-answer{
			background: #fff;
			padding: 20px 65px;
			border-radius: 10px;
			margin-top: 40px;
			position: relative;
			font-weight: 300;
			text-transform: none;
			@include rt('medium'){
			}
			@include rt('small'){
				padding: 20px;
			}
			&:before{
				content: " ";
				width: 0;
				height: 0;
				border: 20px solid transparent;
				border-bottom-color: #fff;
				border-top: 0;
				position: absolute;
				top: -20px;
				left: 30px;
			}

		}
	}
	ul.pagination{
		padding: 0;
		margin: 41px 0;
		.pager-last,
		.pager-first{
			display: none;
		}
		.pager-item{
			>*{
				font-family: $OpenSans;
				font-size: 12px;
				font-weight: 900;
				text-transform: uppercase;
				color: #051a3b;
				padding: 6px 6px;
				&:hover{
					color: #2dc0f6;
				}
			}
		}

		.pager-current{
			>*{
				font-family: $OpenSans;
				font-size: 12px;
				font-weight: 900;
				text-transform: uppercase;
				color: #2dc0f6;
				padding: 6px 6px;
			}
		}

		.pager-previous,
		.pager-next{
			> *{
				color: #051a3b;
				font-family: $OpenSans;
				font-size: 12px;
				font-weight: 900;
				text-decoration: underline;
				text-transform: uppercase;
				padding: 6px 15px;
				&:hover{
					color: #2dc0f6;
					text-decoration: underline;
				}
			}
		}
	}
}