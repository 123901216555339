body.node-type-action{

	.node-action{
		padding-top: 114px;
		padding-bottom: 54px;
		
		.img_wrap {
			position: relative;
			float: left;
			margin: 6px 49px 0px 0;
			@include rt('medium'){
				float: none;
				margin-right: 0px;
				text-align: center;
				.field-name-field-image{
					display: inline-block;
					position: relative;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			@include rt('small'){
				float: none;
				margin-right: 0px;
				text-align: center;
				.field-name-field-image{
					display: inline-block;
					position: relative;
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
			.field-name-field-image{
				/*img{
					border-bottom: 3px solid #2dc0f6;
				}*/
			}
			.action_circle{
				width: 108px;
				height: 108px;
				background-color: #ffab30;
				border-radius: 50%;
				display: flex;
				color: white;
				font-size: 30px;
				font-weight: 300;
				line-height: 25px;
				justify-content: center;
				align-items: center;
				position: absolute;
				right: 41px;
				top: 34px;
				@include rt('medium'){
					width: 69px;
					height: 69px;
					top: 50%;
					transform: translateY(-50%);
					right: 20px;
					font-size: 20px;
				}
				@include rt('small'){
					width: 69px;
					height: 69px;
					top: 50%;
					transform: translateY(-50%);
					right: 20px;
					font-size: 20px;
				}
			}
		}
		.node-title{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 12px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin: 0 0 34px 0;
			@include rt('medium'){
				margin: 34px 0;
			}
			@include rt('small'){
				margin: 34px 0;
			}
			.action {
				font-size: 15px;
				font-weight: 700;
				line-height: 25px;
				color: #ffab30;
				text-transform: uppercase;
			}
		}
		.field-name-body{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.9;
			p{
				margin: 0 0 62px 0;
			}
			blockquote{
				border-left: 41px solid #2dc0f6;
				color: #051a3b;
				font-family: Georgia;
				font-size: 17px;
				font-style: italic;
				padding: 6px 0 10px 24px;
				margin: -5px 0 36px 13px;
				line-height: 1.888;
				@include rt('small'){
					border-left: 15px solid #2dc0f6;
					font-size: 16px;
					padding: 6px 0 10px 10px;
					margin: -5px 0 36px 0px;
				}
				p{
					margin: 0;
				}
			}
			h6{
				font-family: $OpenSans;
				font-size: 15px;
				color: #051a3b;
				font-weight: 700;
				margin: 0 0 6px 0;
			}
			ul{
				margin: 0 0 28px 0px;
				padding: 0;
				li{
					list-style: none;
					position: relative;
					padding: 0 0 0 0px;
					&:before{
						content: '•';
						font-size: 15px;
						color: #ffab30;
						padding: 0 3px 0 0;
					}
				}
			}
		}
	}

}

body.page-actions{
	background: #edf4fa;
	#main-wrapper{
		background-color: #edf4fa;
		padding-top: 83px;
		padding-bottom: 107px;
		@include rt('medium'){
			padding-top: 67px;
		}
		@include rt('small'){
			padding-top: 67px;
		}
	}
	
	.page-header{
		@include make-container();
		padding: 0px 0 0 0;
		margin-bottom: 14px;
		@include rt('medium'){
			text-align: center;
		}
		@include rt('small'){
			text-align: center;
		}
		h1{
			color: #051a3b;
			font-family: $OpenSans;
			font-size: 30px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 6px;
			border-bottom: 3px solid #ffffff;
			padding-bottom: 15px;
			@include rt('medium'){
				font-size: 20px;
				letter-spacing: 4px;
			}
			@include rt('small'){
				font-size: 20px;
				letter-spacing: 4px;
			}
		}
	}

	.view-actions{
		margin: 55px 0 55px 0;
		position: relative;
		@include rt('medium'){
			margin: 15px 0 55px 0;
		}
		@include rt('small'){
			margin: 15px 0 55px 0;
		}
		.view-content{
			@include rt('medium'){
				padding-top: 63px;
			}
			@include rt('small'){
				padding-top: 63px;
			}
		}
		.view-filters {
			position: absolute;
			right: 0px;
			top: -118px;
			@include rt('medium'){
				position: relative;
				top: 0;
			}
			@include rt('small'){
				position: relative;
				top: 0;
			}
			.views-widget-filter-field_category_action_tid_i18n {
				padding: 0px;
				width: 363px;
				@include rt('medium'){
					width: 100%;
					max-width: 363px;
					margin: 0 auto;
					float: none;
				}
				@include rt('small'){
					width: 100%;
					max-width: 363px;
					margin: 0 auto;
					float: none;
				}
			}
			.views-submit-button {
				display: none;
			}
			.form-control.jq-selectbox {
				width: 100%;
				.jq-selectbox__select {
					background:#2dc0f6;
					height: 30px;
					position: relative;
					text-align: left;
					padding-left: 38px;
					line-height: 30px;
					.jq-selectbox__select-text {
						color: white;
						font-size: 15px;
						font-weight: 300;
						font-family: $OpenSans;
					}
				}
				.jq-selectbox__trigger {
					right: 29px;
				}
				.jq-selectbox__dropdown {
					height: 200px!important;
					padding: 11px 19px 25px 39px;
				}
				.jspVerticalBar {
					width: 5px;
					.jspTrack,.jspDrag {
						border-radius: 0px;
					}
					.jspTrack {
						background:url('../images/scroll_select_bg.jpg');
					}
				}
				ul {
					height: 160px;
					width: 100%;
				}
				li {
					font-size: 15px;
					font-weight: 300;
					line-height: 35px;
					color: #051a3b;
					padding-left: 0px;
					&:before {
						display: none;
					}
					&:hover { 
						color: #2dc0f6;
						text-decoration: underline;
					}
				}
			}
		}
		.views-row {
			background: white;
			margin-bottom: 60px;
			@include rt('medium'){
				padding-bottom: 47px;
				max-width: 564px;
				margin: 0 auto;
				margin-bottom: 60px;
			}
			@include rt('small'){
				padding-bottom: 47px;
			}
			.node-action {
				@include rt('medium'){
					max-width: 564px;
				    margin: 0 auto;
				}
				@include rt('small') {
					max-width: 564px;
				    margin: 0 auto;
				}
			}
			&:hover {
				.img_wrap {
					border-right: 3px solid #2DC0F6;
					.action_circle {
						background: #2DC0F6;
					}
				}
				.node-title {
					.action {
						color: #2DC0F6;
					}
				}
				.node-text {
					.node-detail {
						color: #2DC0F6;
					}
				}
			}
			.img_wrap {
				float: left;
				position: relative;
				border-right: 3px solid #ffab30;
				margin-right: 52px;
				@include rt('medium'){
					float: none;
					margin-right: 0px;
					border-right: 0px;
					border-bottom: 3px solid #ffab30;
					text-align: center;
					.field-name-field-image{
						display: inline-block;
						position: relative;
						img{
							max-width: 100%;
							height: auto;
						}
					}
				}
				@include rt('small'){
					float: none;
					margin-right: 0px;
					border-right: 0px;
					border-bottom: 3px solid #ffab30;
					text-align: center;
					.field-name-field-image{
						display: inline-block;
						position: relative;
						img{
							max-width: 100%;
							height: auto;
						}
					}
				}
				.action_circle{
					width: 108px;
					height: 108px;
					background-color: #ffab30;
					border-radius: 50%;
					display: flex;
					color: white;
					font-size: 30px;
					font-weight: 300;
					line-height: 25px;
					justify-content: center;
					align-items: center;
					position: absolute;
					right: 41px;
					top: 34px;
					@include rt('medium'){
						width: 69px;
						height: 69px;
						top: 50%;
						transform: translateY(-50%);
						right: 20px;
						font-size: 20px;
					}
					@include rt('small'){
						width: 69px;
						height: 69px;
						top: 50%;
						transform: translateY(-50%);
						right: 20px;
						font-size: 20px;
					}
				}
			}
			.node-title{
				color: #051a3b;
				font-family: $OpenSans;
				font-size: 12px;
				font-weight: 900;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin: 0 0 13px 0;
				padding-top: 27px;
				line-height: 25px;
				padding-right: 20px;
				@include rt('medium'){
					font-size: 14px;
					text-transform: none;
					text-align: center;
					padding:46px 19px 0 19px;
					letter-spacing: 0px;
					line-height: 1.1;
					margin: 0 0 22px 0;
				}
				@include rt('small'){
					font-size: 14px;
					text-transform: none;
					text-align: center;
					padding:46px 19px 0 19px;
					letter-spacing: 0px;
					line-height: 1.25;
					margin: 0 0 22px 0;
				}
				.action {
					font-size: 15px;
					font-weight: 700;
					line-height: 25px;
					color: #ffab30;
					text-transform: uppercase;
					@include rt('medium'){
						font-size: 14px;
						line-height: 1.25;
					}
					@include rt('small'){
						font-size: 14px;
						line-height: 1.25;
					}
				}
			}
			.node-text {
				color: #051a3b;
				font-weight: 300;
				padding-right: 20px;
				@include rt('medium'){
					font-size: 15px;
					padding: 0px 14px;
					text-align: center;
					line-height: 1.68;
				}
				@include rt('small'){
					font-size: 15px;
					padding: 0px 14px;
					text-align: center;
					line-height: 1.68;
				}
				.node-detail {
					color: #ffab30;
					font-weight: bold;
					@include rt('medium'){
						display: block;
					}
					@include rt('small'){
						display: block;
					} 
				}
			}
			/*&:nth-child(2) {
				.img_wrap {
					border-right: 3px solid #2dc0f6;
					@include rt('medium'){
						border-right: 0;
						border-bottom: 3px solid #2dc0f6;
					}
					@include rt('small'){
						border-right: 0;
						border-bottom: 3px solid #2dc0f6;
					}
					.action_circle{
						background-color: #2dc0f6;
					}
				}
				.node-title{
					.action {
						color: #2dc0f6;
					}
				}
				.node-text {
					.node-detail {
						color: #2dc0f6; 
					}
				}
			}*/
		}
		/*.view-content{
			display: flex;
			flex-direction:row;
			flex-wrap:wrap;
			align-items:flex-start;
			justify-content:space-between;
			.views-row{
				width: 363px;
				height: 600px;
				background-color: #ffffff;
				margin: 0 0 40px 0;
				.node-actions{
					.content{
						.field-name-field-image{
							border-bottom: 3px solid #2dc0f6;
						}
						.node-title{
							margin: 55px 0 0 0;
							text-align: center;
							padding: 0 15px;
							a{
								color: #051a3b;
								font-family: $OpenSans;
								font-size: 12px;
								font-weight: 900;
								text-transform: uppercase;
								letter-spacing: 1px;
							}
						}
						.field-name-body{
							text-align: center;
							color: #051a3b;
							font-family: $OpenSans;
							font-size: 15px;
							font-weight: 300;
							margin: 50px 0 0 0;
							padding: 0px 52px;
							line-height: 2;
							p{
								margin: 0;
							}
						}
						.node-detail{
							margin: 45px 0 0 0;
							a{
								display: flex;
								flex-direction:row;
								align-items:center;
								justify-content:center;
								width: 220px;
								height: 40px;
								margin: 0 auto 0 auto;
								border: 1px solid rgba(5,26,59,.3);
								border-radius: 20px;
								color: #767b88;
								font-family: $OpenSans;
								font-size: 12px;
								font-weight: 900;
								text-transform: uppercase;
								text-decoration: none;
								letter-spacing: 1px;
								&:hover{
									background-color: #2dc0f6;
									border-color: #2dc0f6;
									color: white;
								}
							}
						}
					}
				}
			}
		}*/

		ul.pagination{
			padding: 0;
			margin: 41px 0;
			.pager-last,
			.pager-first{
				display: none;
			}
			.pager-item{
				>*{
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					color: #051a3b;
					padding: 6px 6px;
					&:hover{
						color: #2dc0f6;
					}
				}
			}
			
			.pager-current{
				>*{
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					color: #2dc0f6;
					padding: 6px 6px;
				}
			}

			.pager-previous,
			.pager-next{
				> *{
					color: #051a3b;
					font-family: $OpenSans;
					font-size: 12px;
					font-weight: 900;
					text-decoration: underline;
					text-transform: uppercase;
					padding: 6px 15px;
					&:hover{
						color: #2dc0f6;
						text-decoration: underline;
					}
				}
			}
		}

	}

	.pane-custom{
		margin: 59px 0 0 0;
		color: #051a3b;
		font-family: $OpenSans;
		font-size: 15px;
		font-weight: 300;
		line-height: 1.88;
	}

}