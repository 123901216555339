.tax_price {
	background: #edf4fa;
	h1 {
		@include make-container();
		text-align: center;
		color: #ffab2f;
		font-size: 30px;
		font-weight: 300;
		text-transform: uppercase;
		font-family: "Open Sans";
		padding-top: 99px;
    	letter-spacing: 6px;
    	@include rt('small'){
    		font-size: 20px;
    	}
	}
	.pane-taxonomy-term-description {
		color: #05193b;
		font-size: 15px;
		@include rt('small'){
			font-size: 14px;
		}
		@include make-container();
		text-align: center;
	}
	.view-prices {
		padding-top: 26px;
		.views-row {
			.views-field-title {
				@include make-container();
				border: 0px;
				border-radius: 0px;
				background: none;
				border-bottom: 5px solid white;
				height: 84px;
				position: relative;
				color: #010f2f;
				a {
					font-size: 24px;
					font-weight: 300;
					font-family: "Open Sans";
					@include rt('small'){
						font-size: 20px;
					}
				}
				padding-left: 51px;
			    letter-spacing: 1px;
			    padding-top: 37px;
				.ui-accordion-header-icon {
					width: 35px;
					height: 35px;
					background: url('../images/tax_price_plus.png');
					position: absolute;
					right: 49px;
					top: 42px;
					left: auto;
				}
				&.ui-accordion-header-active {
					a {
						color: #ffab2f;
					}
					.ui-accordion-header-icon {
						background: url('../images/tax_price_minus.png');
					}
				}
			}
			.ui-accordion-content {
				background: #dfe8f0;
				padding: 0px;
				border:0px;
				border-radius: 0px;
			}
		}
		.view-price-direction {
			padding-bottom: 60px;
			@include make-container();
			h3 {
				padding-top: 60px;
				color: #05193b;
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				margin: 0px;
				text-transform: uppercase;
				letter-spacing: 1px;
				@include rt('small'){
					font-size: 12px;
				}
				&:after,&:before {
					background: url('../images/tax_price_group.png');
					display: inline-block;
					width: 9px;
					height: 9px;
					content: '';
				}
				&:after{
					margin-left: 5px;
				}
				&:before {
					margin-right: 5px;
				}
			}
		}
		.views-field-field-disease-price {
			padding-top: 40px;
		}
      .field-name-field-service-procedure-name {
        color: #051a3b;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        padding: 16px 0px;
        background-color: white;
        border-radius: 30px;
        padding-left: 49px;
        padding-right: 90px;
        position: relative;
        cursor: pointer;
        @include rt('small'){
        	padding-left: 31px;
        	padding-right: 47px;
        	font-size: 13px;
        }
        &:after {
          content: '';
          width: 35px;
          height: 35px;
          position: absolute;
          top: 50%;
          right: 49px;
          margin-top: -17.5px;
          background: url('../images/disease_plus.png');
          @include rt('small'){
          	right: 10px;
          }
        }
        &.opened {
          color: white;
          background: #768fa5;
          border-radius: 30px 30px 0px 0px;
          &:after {
            background: url('../images/disease_minus.png');
          }
        }
      }

		.paragraphs-item-service-procedure{
			> .content{
				> .paragraphs-items-field-service-procedure-prices{
					@include rt('small'){
						overflow-x:scroll;
					}
					> .field-name-field-service-procedure-prices{
            @include rt('small'){
						  width: 728px;
            }
					}
				}
			}
		}

      .paragraphs-items-field-service-procedure-prices {
        padding-top: 4px;
        display: none;
      }
      .paragraphs-item-service-prices {
        & > .content {
          display: flex;
          flex-wrap: wrap;
          .paragraphs-items-field-p-service-price {
            flex:0 0 100%;
          }
          .field-name-field-column-name-1 {
            width: calc(100% - 476px);
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            padding-left: 38px;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-2 {
            width: 234px;
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-3 {
            width: 234px;
            background: #01102f;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
        }
      }
      .paragraphs-item-service-price-item {
      	margin-top: 4px;
      	& > .content {
          display: flex;
          flex-wrap: wrap;
          .field-name-field-column1 {
            width: calc(100% - 476px);
            background: white;
            margin-right: 4px;
            padding: 11px 0px;
            padding-left: 38px;
            color: #05193b; /* text color */
      			font-size: 15px;
            &.summary-row {
              background: #efddc2;
              color: #95784d;
            }
      			@include rt('small'){
      				font-size: 14px;
      			}
          }
          .field-name-field-column2 {
            width: 234px;
            background: #d5e0e4;
            margin-right: 4px;
            padding: 11px 0px;
            text-align: center;
            color: #05193b; /* text color */
      			font-size: 15px;
            &.summary-row {
              background: #ffab30;
              color: #fff;
            }
      			@include rt('small'){
      				font-size: 14px;
      			}
          }
          .field-name-field-column3 {
            width: 234px;
            background: #768fa5;
            padding: 11px 0px;
            text-align: center;
            color: white; /* text color */
      			font-size: 15px;
            &.summary-row {
              background: #ffab30;
              color: #fff;
            }
      			@include rt('small'){
      				font-size: 14px;
      			}
          }
        }
      }
      .field-name-field-p-service-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 4px;
      		}
      	}
      }
      .field-name-field-disease-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 60px;
      		}
      	}
      }
	}
	.pane-taxonomy-term-field-text-before-form,.pane-taxonomy-term-field-text-after-form {
		@include make-container();
		padding-top: 86px;
		color: #05193b;
		font-weight: 300px;
		font-size: 15px;
		padding-bottom: 88px;
		p {
			margin-bottom: 30px;
		}
		ul {
			margin: 0px;
			list-style: none;
			padding-left: 0px;
			margin-bottom: 30px;
			li {
				padding-left: 0px;
				&:before {
					content: '•';
					color: #ffab30;
				    font-size: 23px;
				    line-height: 23px;
				    position: relative;
				    top: 4px;
				    margin-right: 5px;
				}
			}
		}
	}
	.pane-webform {
		background: white;
		padding-bottom: 112px;
		.pane-content {
			@include make-container();
		}
	}
	.view-price-without-direction {
		.views-row {
			.views-field-title {
				@include make-container();
				border: 0px;
				border-radius: 0px;
				background: none;
				border-bottom: 5px solid white;
				height: 84px;
				position: relative;
				color: #010f2f;
				a {
					font-size: 24px;
					font-weight: 300;
					font-family: "Open Sans";
					@include rt('small'){
						font-size: 20px;
					}
				}
				padding-left: 51px;
			    letter-spacing: 1px;
			    padding-top: 37px;
				.ui-accordion-header-icon {
					width: 35px;
					height: 35px;
					background: url('../images/tax_price_plus.png');
					position: absolute;
					right: 49px;
					top: 42px;
					left: auto;
				}
				&.ui-accordion-header-active {
					a {
						color: #ffab2f;
					}
					.ui-accordion-header-icon {
						background: url('../images/tax_price_minus.png');
					}
				}
			}
			.ui-accordion-content {
				background: #dfe8f0;
				padding: 0px;
				border:0px;
				border-radius: 0px;
			}
			.views-field-field-disease-price {
				@include make-container();
				.paragraphs-item-service-procedure {
					padding-bottom: 40px;
				}
			}
		}
		.view-price-direction {
			padding-bottom: 60px;
			@include make-container();
			h3 {
				padding-top: 60px;
				color: #05193b;
				font-size: 14px;
				font-weight: bold;
				text-align: center;
				margin: 0px;
				text-transform: uppercase;
				letter-spacing: 1px;
				@include rt('small'){
					font-size: 12px;
				}
				&:after,&:before {
					background: url('../images/tax_price_group.png');
					display: inline-block;
					width: 9px;
					height: 9px;
					content: '';
				}
				&:after{
					margin-left: 5px;
				}
				&:before {
					margin-right: 5px;
				}
			}
		}
		.views-field-field-disease-price {
			padding-top: 40px;
		}
      .field-name-field-service-procedure-name {
        color: #051a3b;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        padding: 16px 0px;
        background-color: white;
        border-radius: 30px;
        padding-left: 49px;
        padding-right: 90px;
        position: relative;
        cursor: pointer;
        @include rt('small'){
        	padding-left: 31px;
        	padding-right: 47px;
        	font-size: 13px;
        }
        &:after {
          content: '';
          width: 35px;
          height: 35px;
          position: absolute;
          top: 50%;
          right: 49px;
          margin-top: -17.5px;
          background: url('../images/disease_plus.png');
          @include rt('small'){
          	right: 10px;
          }
        }
        &.opened {
          color: white;
          background: #768fa5;
          border-radius: 30px 30px 0px 0px;
          &:after {
            background: url('../images/disease_minus.png');
          }
        }
      }

		.paragraphs-item-service-procedure{
			> .content{
				> .paragraphs-items-field-service-procedure-prices{
					@include rt('small'){
						overflow-x:scroll;
  					> .field-name-field-service-procedure-prices{
  						width: 728px;
  					}
          }
				}
			}
		}

      .paragraphs-items-field-service-procedure-prices {
        padding-top: 4px;
        display: none;
      }
      .paragraphs-item-service-prices {
        & > .content {
          display: flex;
          flex-wrap: wrap;
          .paragraphs-items-field-p-service-price {
            flex:0 0 100%;
          }
          .field-name-field-column-name-1 {
            width: calc(100% - 476px);
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            padding-left: 38px;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-2 {
            width: 234px;
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-3 {
            width: 234px;
            background: #01102f;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
        }
      }
      .paragraphs-item-service-price-item {
      	margin-top: 4px;
      	& > .content {
          display: flex;
          flex-wrap: wrap;
          .field-name-field-column1 {
            width: calc(100% - 476px);
            background: white;
            margin-right: 4px;
            padding: 11px 0px;
            padding-left: 38px;
            color: #05193b; /* text color */
			font-size: 15px;
			@include rt('small'){
				font-size: 14px;
			}
          }
          .field-name-field-column2 {
            width: 234px;
            background: #d5e0e4;
            margin-right: 4px;
            padding: 11px 0px;
            text-align: center;
            color: #05193b; /* text color */
			font-size: 15px;
			@include rt('small'){
				font-size: 14px;
			}
          }
          .field-name-field-column3 {
            width: 234px;
            background: #768fa5;
            padding: 11px 0px;
            text-align: center;
            color: white; /* text color */
			font-size: 15px;
			@include rt('small'){
				font-size: 14px;
			}
          }
        }
      }
      .field-name-field-p-service-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 4px;
      		}
      	}
      }
      .field-name-field-disease-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 60px;
      		}
      	}
      }
	}

}