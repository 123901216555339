.ajax-progress-throbber { display: none};

.language-switcher-locale-url {
  .uk {
    display: none!important;
  }
}

#header,#footer {
  .header-logo {
    display: block;
    float: left;
    margin-top: 18px;
    @include rt('medium'){
      float: none;
      order:2;
      margin-top: 24px;
      img{
        float: none;
        margin: 0 auto;
        width: 119px;
        height: 43px;
      }
    }
    @include rt('small'){
      float: none;
      order:2;
      margin-top: 24px;
      img{
        float: none;
        margin: 0 auto;
        width: 119px;
        height: 43px;
      }
    }
  }
  .top-part {
    background:#182643;
    min-height: 100px;
    @include rt('medium'){
      height: 180px;
      background: #01102f;
    }
    @include rt('small'){
      height: 180px;
      background: #01102f;
      position: relative;
    }
     > .h-tp{
      @include rt('medium'){
        display: flex;
        flex-direction:column;
        position: relative;
        height: 100%;
      }
      @include rt('small'){
        display: flex;
        flex-direction:column;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        height: 100%;
      }
    }

    .serv-menu-mobile{
      display: none;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 49%;
      transform:translateY(-50%);
      left: 10px;
      background: url('../images/mobile/serv-menu-burg.png') no-repeat;
      cursor: pointer;
      z-index: 10;
      @include rt('medium'){
        display: block;
        left: 0px;
      }
      @include rt('small'){
        display: block;
      }
    }
    .main-menu-mobile{
      display: none;
      width:30px;
      height: 24px;
      position: absolute;
      top: 46%;
      transform:translateY(-50%);
      right: 10px;
      background: url('../images/mobile/main-menu-burg.png') no-repeat;
      cursor: pointer;
      z-index: 10;
      @include rt('medium'){
        display: block;
        right: 0px;
      }
      @include rt('small'){
        display: block;
      }
    }

  }
  .bottom-part {
    background: #47546c;
    @include rt('medium'){
      display: none;
    }
    @include rt('small'){
      display: none;
    }
    ul.menu {
      display: flex;
      justify-content: space-between;
      &:before,&:after {
        display: none;
      }
      li {
        display: inline-block;
        position: relative;
        a {
          font-size: 11px;
          font-weight: 800;
          line-height: 40px;
          text-transform: uppercase;
          color: white;
          padding: 0px;
          letter-spacing: 1px;
          .caret {
            display: none;
          }
        }
        &:hover {
          a {
            color: #2dc0f6;
          }
        }
        ul {
          display: none;
          z-index: 5;
        }
        &:hover {
          ul {
            display: block;
            position: absolute;
            top: 40px;
            left: -42px;
            background: #47546c;
            margin: 0px;
              padding: 20px 40px;
              width: 302px;
              border-radius: 0px 0px 5px 5px;
            li {
              display: block;
              line-height: 30px;
              padding-left: 10px;
              &:before {
                content: '•';
                color: #2fc0f9;
                font-size: 19px;
                position: absolute;
                top: 1px;
                left: 0;
              }
              a {
                line-height: 30px;
                display: inline-block;
                font-weight: 700;
                color: white;
              }
              &:hover,
              &.active{
                a {
                  color: #2dc0f6;
                }
              }
            }
          }
        }
      }
    }
  }
  .region-language {
    display: block;
      float: left;
      padding-left: 6px;
      padding-top: 17px;
      .region-language{
        float: none;
        padding-left: 0px;
        padding-top: 0px;
      }
      @include rt('medium'){
        display: none;
      }
      @include rt('small'){
        display: none;
      }
    li {
      padding-bottom: 4px;
      list-style: none;
      a {
        color: white;
        font-size: 10px;
        font-weight: 700;
        line-height: 70px;
        text-transform: uppercase;
        border: 1px solid #edf4fa;
        line-height: 22px;
        width: 24px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
      }
      span.locale-untranslated {
        color: white;
        font-size: 10px;
        font-weight: 700;
        line-height: 70px;
        text-transform: uppercase;
        border: 1px solid #edf4fa;
        line-height: 22px;
        width: 24px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
      }
      &.active {
        a {
          color: #182643;
          background: #edf4fa;
        }
      }
    }
  }
  .big-block {
    float: left;
    padding-left: 44px;
    padding-top: 11px;
    @include rt('medium'){
      float: none;
      padding-left: 0px;
      padding-top: 0px;
      order:3;
    }
    @include rt('small'){
      float: none;
      padding-left: 0px;
      padding-top: 0px;
      order:3;
    }
    .top-menu{
      @include rt('medium'){
        display: none;
      }
      @include rt('small'){
        display: none;
      }
    }
    #main-menu {
      display: flex;
      li {
        &:before {
          content: '•';
          color: #2dc0f6;
          display: inline-block;
          padding: 0px 7px;
        }
        & > a {
          font-size: 11px;
          font-weight: 300;
          line-height: 30px;
          text-transform: uppercase;
          color: white;
          display: inline-block;
          padding: 0px;
          letter-spacing: 1px;
          position: relative;
          .fa-caret-down {
            display: none;
          }
        }
        &:nth-child(1) {
          &:before {
            display: none;
          }
        }
        &.expanded:hover {
          position: relative;
          & > a {
            background: #303c56;
            &:before {
              content: '';
              background: #303c56;
              display: block;
              width: 14px;
              height: 30px;
              position: absolute;
              left: -14px;
              top: 0px;
              border-radius: 5px 0px 0px 0px;
              z-index: 1;
            }
            &:after {
              content: '';
              background: #303c56;
              display: block;
              width: 14px;
              position: absolute;
              height: 30px;
              right: -14px;
              top: 0px;
              border-radius: 0px 5px 0px 0px;
              z-index: 1;
            }
          }
          &.first {
            ul {
              left: -40px;
            }
          }
          ul {
            display: block;
            background: #303c56;
            margin: 0px;
            background-color: #303c56;
            border-radius: 5px;
            padding: 21px 40px;
            width: 278px;
            position: absolute;
            top: 29px;
            left: -21px;
            border:0px;
            li {
              background: none;
              &:before {
                content: '';
                display: none;
              }
              a {
                font-size: 11px;
                font-weight: 300;
                line-height: 30px;
                text-transform: uppercase;
                color: #edf4fa;
                background: none;
                &:before {
                  color: #ffab30;
                  content: '•';
                  left: 0px;
                  position: initial;
                  display: inline-block;
                  width: 8px;
                }
                &:hover,&.active {
                  color: #ffab30;
                }
              }
            }
          }
        }
      }
    }
    .under-top-menu {
      display: flex;
      justify-content: space-between;
      @include rt('medium'){
        justify-content:center;
        margin-top: 12px;
      }
      @include rt('small'){
        justify-content:center;
        margin-top: 12px;
      }
      .region-medical-manager {
        position: relative;
        @include rt('medium'){
          display: none;
        }
        @include rt('small'){
          display: none;
        }
        .block__title {
          font-family: "Open Sans";
          color: white;
          font-size: 12px;
          font-weight: 800;
          line-height: 30px;
          text-decoration: underline;
          background-image: url('../images/arroy_blue_header.png');
          background-position: center right 14px;
          background-repeat: no-repeat;
          border-radius: 5px 5px 0px 0px;
          margin: 0px;
            margin-top: 11px;
            padding-left: 15px;
            padding-right: 26px;
            position: relative;
            left: -15px;
            width: 226px;
        }
        .block__content {
          border-radius: 5px;
          background: #303c56;
          position: absolute;
          top: 41px;
          left: -35px;
            padding: 0px 34px;
            width: 266px;
            font-size: 11px;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding-top: 7px;
          padding-bottom: 25px;
          display: none;
          z-index: 5;
          p {
            margin: 0px;
          }
            strong {
            color: white;
            padding-top: 20px;
              display: inline-block;
            }
        }
        &:hover {
          .block__title {
            background: #303c56;
            background-image: url('../images/arroy_blue_header.png');
            background-position: center right 14px;
            background-repeat: no-repeat;
            color: #2dc0f6;
          }
          .block__content {
            display: block;
          }
        }
      }
      .region-phone-header {
        color: white;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        margin-right: -9px;
        margin-top: -3px;
        letter-spacing: 2px; 
        text-transform: uppercase;
        max-width: 200px; 
        @media screen and (max-width: 1199px) {
          max-width: inherit;
          width: 100%;
        }
        @media screen and (max-width: 639px) {
          font-size: 16px;
          letter-spacing: 0px;
          padding: 0px 10px;
          padding-top: 8px;
        }
        .block__content {
          @media screen and (max-width: 1199px) {
            display: flex;
            justify-content: space-between;
          }
          a {
            padding-left: 32px;
            background: url('../images/phone_top_ico.png') center left no-repeat;
            color: white;
            text-decoration: none;
            &:nth-child(2) {
              background: url('../images/phone_top_ico_mobile.png') center left no-repeat;
            }
          }
        }
      }
    }
  }
  .popup-buttons {
    float: right;
    @include rt('medium'){
      float: none;
      order:1;
    }
    @include rt('small'){
      float: none;
      order:1;
    }
    ul {
      @include rt('medium'){
        text-align: center;
        font-size: 0;
      }
      li {
        float: left;
        @include rt('medium'){
          /*width: 50%;*/
          float: none;
          display: inline-block;
        }
        @include rt('small'){
          width: 50%;
        }
        a {
          color: white;
          font-size: 11px;
          font-weight: 700;
          line-height: 80px;
          text-transform: uppercase;
          display: inline-block;
          width: 187px;
          text-align: center;
          background: rgba(255,255,255,0.1);
          letter-spacing: 1px;

          &:focus {
            outline: none;
          }

          @include rt('medium'){
            /*width: 100%;
            height: 40px;
            line-height: 22px;*/
            width: 190px;
            line-height: 1;
            padding: 14px 15px 15px 15px;
          }
          @include rt('small'){
            width: 100%;
            height: 40px;
            line-height: 22px;
            padding-left: 0px;
            padding-right: 0px;
          }
        }
        &:nth-child(1) {
          a{
            @include rt('medium'){
              border-radius: 0px 0px 0px 30px;
            }
          }
        }
        &:nth-child(2) {
          a {
            background: #2dc0f6;
            @include rt('medium'){
              border-radius: 0px 0px 30px;
            }
          }
        }
      }
    }
  }
}
.front {
  #header {
    position: absolute;
      width: 100%;
      left: 0px;
      top: 0px;
      @include rt('medium'){
        position: relative;
      }
      @include rt('small'){
        position: relative;
      }
    .top-part {
      background: rgba(1,16,47,0.9);
    }
    .bottom-part {
      background: rgba(255,255,255,0.3);
    }
  }
}

footer#footer{
  .top-part{
    @include rt('medium'){
      height: auto;
      padding-top: 68px;
      padding-bottom: 54px;
    }
    @include rt('small'){
      height: auto;
      padding-top: 7px;
      padding-bottom: 43px;
    }
    .header-logo{
      @include rt('medium'){
        display: none;
      }
      @include rt('small'){
        display: none;
      }

    }

    .big-block{
      @include rt('medium'){
        display: none;
      }
      @include rt('small'){
        display: none;
      }

    }

    .popup-buttons{
      @include rt('medium'){
        display: none;
      }
      @include rt('small'){
        display: none;
      }
    }

    .region-address-copirate{
      @include rt('medium'){
        justify-content:flex-start;
        float: left;
        width: 50%;
        padding-left: 69px;
        padding-top: 0;
      }
      @include rt('small'){
        justify-content:center;
      }
      #block-block-3{
        @include rt('medium'){
          display: none;
        }
        @include rt('small'){
          display: none;
        }
      }
    }

    .region-order-develop{
      @include rt('medium'){
        justify-content:flex-end;
        padding-top: 0px;
        float: right;
        width: 50%;
        clear: none;
        padding-right: 84px;
      }
      @include rt('small'){
        justify-content:center;
        padding-top: 8px;
      }
      #block-block-5{
        @include rt('medium'){
          display: none;
        }
        @include rt('small'){
          display: none;
        }
      }
    }

  }
}

#m-serv-menu{
  display: none;
  width: 265px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -265px;
  background-color: #01102f;
  transition: all 0.2s ease-in;
  padding: 72px 24px 0 24px;
  @include rt('medium'){
    display: block;
  }
  @include rt('small'){
    display: block;
  }
  .second-menu{
    > ul{
      > li{
        > a{
          font-size: 12px;
          font-weight: 900;
          color: #fff;
          text-transform: uppercase;
          padding: 0 0 9px 0;
          .caret{
            display: none;
          }
        }
        > ul{
          margin-left: 1px;
          margin-bottom: 30px;
          > li{
            position: relative;
            padding: 0 0 0 7px;
            &:before{
              content: '';
              position: absolute;
              width: 3px;
              height: 3px;
              background-color: #2fc0f9;
              top: 50%;
              left: 0;
              transform:translateY(-50%);
            }
            > a{
              font-size: 12px;
              font-weight: 400;
              color: #fff;
              padding: 0 0 0px 0;
              margin: 0 0 9px 0;
            }
          }
        }
      }
    }
  }
}

#m-main-menu{
  display: none;
  width: 265px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -265px;
  background-color: #01102f;
  transition: all 0.2s ease-in;
  padding: 32px 0 0 48px;
  @include rt('medium'){
    display: block;
  }
  @include rt('small'){
    display: block;
  }
  .region-language {
    display: block;
    float: left;
      ul{
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        font-size: 0;
        li {
          list-style: none;
          display: inline-block;
          margin-right: 9px;
          a {
            color: white;
            font-size: 10px;
            font-weight: 700;
            line-height: 70px;
            text-transform: uppercase;
            border: 1px solid #edf4fa;
            line-height: 22px;
            width: 24px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
          }
          &.active {
            a {
              color: #182643;
              background: #edf4fa;
            }
          }
        }
      }
  }
  .clear {
    clear: both;
  }
  .header-social-links-block {
      float: left;
      margin-left: 11px;
      &:before {
        content: '';
        display: block;
        float: left;
        width: 3px;
        height: 24px;
        background: #2dc0f6;
        border-radius: 3px;
      }
      .block__content {
        float: left;
        padding-left: 17px;
        div {
          float: left;
          margin: 0px 5px;
        }
        a {
          display: block;
          font-size: 0;
          height: 24px;
          margin-bottom: 6px;
          text-indent: -99999px;
          width: 24px;
        }
        .header-facebook {
          background: url(../images/header_facebook.png) center no-repeat;
        }
        .header-youtube {
          background: url(../images/header_youtube.png) center no-repeat;
        }
      }
  } 
  ul#mobile-main-menu{
    padding: 0 0 0 0px;
    margin: 54px 0 0 0;
    list-style: none!important;
    > li{
      list-style: none!important;
      margin-bottom: 8px;
      > a{
        font-size: 18px;
        font-weight: 900;
        color: #fff;
        text-transform: uppercase;
        padding: 0 0 9px 0;
        .fa-caret-down{
          display: none;
        }
      }
      &.open {
        > ul{
          display: block;
        }
      }
      > ul{
        margin-left: 1px;
        margin-bottom: 30px;
        position: relative;
        float: none;
        min-width: initial;
        padding: 0;
        top: 0;
        border:0;
        box-shadow: none;
        background: none;
        display: none;
        > li{
          position: relative;
          padding: 0 0 0 7px;
          &:before{
            content: '';
            position: absolute;
            width: 3px;
            height: 3px;
            background-color: #2fc0f9;
            top: 50%;
            left: 0;
            transform:translateY(-50%);
          }
          &:hover{
            background: none;
          }
          > a{
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            padding: 0 0 0px 0;
            margin: 0 0 9px 0;
            &:hover{
              background: none;
            }
          }
        }
      }
    }
  }
  #block-block-8{
    margin: 61px 0 0 0px;
    .mmr-title{
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 1px;
      letter-spacing: 0.6px;
    }
    p{
      font-size: 11px;
      text-transform: uppercase;
      color: #fff;
      font-weight: 400;
      margin: 0 0 1px 0;
      letter-spacing: 0.8px;
      span{
        color: #2dc0f6;
      }
    }
    .first-squad{
      margin: 0 0 21px 0;
    }
  }
}

#header {
  .popup-buttons {
    .header-social-links-block {
      float: left;
      margin-right: 42px;
      @include rt('medium') {
        display: none;
      }
      @include rt('small') {
        display: none;
      }
      .block__content {
        padding-top: 18px;
        a {
          display: block;
          font-size: 0;
          height: 24px;
          margin-bottom: 6px;
          text-indent: -99999px;
          width: 24px;
        }
        .header-facebook {
          background: url(../images/header_facebook.png) center no-repeat;
        }
        .header-youtube {
          background: url(../images/header_youtube.png) center no-repeat;
        }
      }
    }
    .popup-menu {
      display: inline-block;
      float: right;
      @include rt('medium') {
        float: none;
        text-align: center;
        width: 100%;
      }
      @include rt('small') {
        float: none;
        text-align: center;
        width: 100%;
      }
      ul.menu {
        li {
          @include rt('small') {
            width: 50%;
          }
          &:first-child {
            @include rt('medium') {
              border-radius: 0px 0px 0px 30px;
            }
          }
          &:last-child {
            @include rt('medium') {
              border-radius: 0px 0px 30px;
            }
          }
          a {
            display: table-cell;
            height: 100px;
            line-height: 15px;
            padding: 0 45px;
            vertical-align: middle;
            width: 164px;
            @include rt('medium') {
              display: inline-block;
              height: 40px;
              line-height: 40px;
              padding: 0 5px;
              text-align: center;
              width: 190px;
            }
            @include rt('small') {
              display: inline-block;
              height: 40px;
              line-height: 40px;
              padding: 0 5px;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}


body.m-serv-menu-active{
  #m-serv-menu{
    @include rt('medium'){
      left:0px;
    }
    @include rt('small'){
      left:0px;
    }
  }
  #all-wrapper{
    @include rt('medium'){
      transition:all 0.2s ease-in;
      left: 265px;
      right: auto;
    }
    @include rt('small'){
      transition:all 0.2s ease-in;
      left: 265px;
      right: auto;
    }
  }
}

body.m-main-menu-active{
  #m-main-menu{
    @include rt('medium'){
      right:0px;
    }
    @include rt('small'){
      right:0px;
    }
  }
  #all-wrapper{
    @include rt('medium'){
      transition:all 0.2s ease-in;
      left: auto;
      right: 265px;
    }
    @include rt('small'){
      transition:all 0.2s ease-in;
      left: auto;
      right: 265px;
    }
  }
}