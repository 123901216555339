.node-type-spa {
  #header {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    .bottom-part {
      background-color: rgba(1,16,47,.7);
    }
    @media (max-width: 767px) {
      position: relative;
    }
  }
  #page-header {
    left: 0;
    position: absolute;
    top: 140px;
    width: 100%;
    z-index: 2;
  }
  #panel-bootstrap-region-center {
    position: relative;
  }
  .field-name-field-spa-top-bg {
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 937px;
      width: 100%;
      @media (max-width: 767px) {
        height: 140px;
      }
    }
  }
  .pane-node-title {
    left: 0;
    position: absolute;
    top: 214px;
    width: 100%;
    @media (max-width: 767px) {
      position: relative;
      padding: 30px 0px;
      top: 0px;
    }
    .pane-content {
      @extend .container;
      @include rt('small'){
        text-align: center;
      }
      h1 {
        background-color: #ffab30;
        color: #fff;
        display: inline-block;
        font-family: "Open Sans";
        font-size: 40px;
        font-weight: 300;
        margin: 0;
        padding: 23px 20px;
        text-transform: uppercase;
        @include rt('small'){
          font-size: 30px;
        }
      }
    }
  }
  .field-name-field-spa-top-text {
    left: 0;
    position: absolute;
    top: 335px;
    width: 100%;
    @include rt('small'){
      position: relative;
      top: 0px;
      text-align: center;
    }
    .field-items {
      @extend .container;
      .field-item {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        width: 450px;
        @include rt('small'){
          width: 100%;
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
          font-size: 15px;
          color: #051a3b;
        }
        p {
          margin-bottom: 30px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            padding-left: 12px;
            position: relative;
            &:before {
              background-color: #ffab30;
              border-radius: 50%;
              content: '';
              height: 5px;
              left: 0;
              position: absolute;
              top: 10px;
              width: 5px;
            }
          }
        }
      }
    }
  }
  .view-id-spa_tours {
    margin-top: -215px;
    @include rt('small'){
      margin-top: 40px;
    }
  }
  .field-name-body {
    @extend .container;
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    padding: 60px 0;
    @include rt('small'){
      padding: 30px 10px;
    }
  }
  .field-name-field-text-after-video {
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    margin-top: 85px;
  }
}

.view-id-spa_tours {
  @extend .container;
  .view-header {
    color: #fff;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 10px;
    @include rt('small'){
      color: #FFAD17;
      font-size: 24px;
      text-align: center;
    }
  }
  .slick {
    position: relative;
  }
  .slick__arrow {
    left: 0;
    margin-left: 215px;
    position: absolute;
    top: -50px;
    width: calc(100% - 215px);
    @include rt('small'){
      margin-left: 0px;
      position: relative;
      top:0;
      width:100%;
      text-align: center;
      margin-top: 20px;
    }
    &:after {
      background-color: #fff;
      content: '';
      position: absolute;
      height: 1px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 83px);
      @include rt('small'){
        content:none;
      }
    }
  }
  .slick-arrow {
    border: none;
    height: 29px;
    margin-right: 6px;
    outline: none;
    text-indent: -99999px;
    width: 28px;
    &:hover {
      background-position: 0 -29px;
    }
  }
  .slick-prev {
    background: url(../images/slider_arrows_prev.png) 0 0 no-repeat;
    @include rt('small'){
      background-position: 0 -29px;
    }
  }
  .slick-next {
    background: url(../images/slider_arrows_next.png) 0 0 no-repeat;
    @include rt('small'){
      background-position: 0 -29px;
    }
  }
}

.pane-node-field-spa-node-price {
  background: #edf4fa;
  padding: 50px 0 95px 0;
  .pane-content {
    @extend .container;
  }
}

.paragraphs-item-spa-prices {
  .field-name-field-spa-prices-title {
    margin-bottom: 35px;
    text-align: center;
    .t1 {
      color: #2dc0f6;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 4px;
      line-height: 30px;
      text-transform: uppercase;
    }
    .t2 {
      color: #01102f;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 13px;
      text-transform: uppercase;
    }
  }
  .field-name-field-first-field {
    align-items: center;
    background: #01102f;
    border-bottom: 4px solid #edf4fa;
    border-right: 4px solid #edf4fa;
    color: white;
    float: left;
    font-size: 12px;
    font-weight: 700;
    min-height: 49px;
    line-height: normal;
    justify-content: left;
    display: flex;
    padding-left: 38px;
    padding-right: 38px;
    width: 60%;
    @include rt('medium'){
      width: 55%;
    }
    @include rt('small'){
      width: 55%;
    }
  }
  .field-name-field-second-field {
    align-items: center;
    background: #01102f;
    border-bottom: 4px solid #edf4fa;
    color: white;
    float: right;
    font-size: 12px;
    font-weight: 700;
    min-height: 49px;
    justify-content: center;
    display: flex;
    text-align: center;
    width: 40%;
    @include rt('medium'){
      width: 45%;
    }
    @include rt('small'){
      width: 45%;
    }
    .orange {
      color: #ffab30;
    }
  }
  > .content {
    > .field-name-field-second-field {
      align-items: center;
      border: none;
      display: flex;
      justify-content: center;
      height: 49px;
      line-height: normal;
    }
  }
  .paragraphs-items-field-spa-prices {
    display: inline-block;
    width: 100%;
  }
}
.paragraphs-item-spa-prices-group {
  width: 100%;
  > .content {
    display: flex;
    align-items: stretch;
  }
}
.paragraphs-items-field-spa-prices {
  .field-name-field-first-field {
    background: #ffffff;
    border-bottom: 4px solid #edf4fa;
    border-right: 4px solid #edf4fa;
    color: #051a3b;
    float: left;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    padding-bottom: 9px;
    padding-left: 38px;
    padding-top: 9px;
    width: 60%;
    @include rt('medium'){
      width: 55%;
    }
    @include rt('small'){
      width: 55%;
    }
  }
  .field-name-field-second-field {
    background: #768fa5;
    border-bottom: 4px solid #edf4fa;
    color: #fff;
    float: right;
    font-size: 15px;
    font-weight: 300;
    line-height: 27px;
    padding-bottom: 9px;
    padding-top: 9px;
    text-align: center;
    width: 40%;
    @include rt('medium'){
      width: 45%;
    }
    @include rt('small'){
      width: 45%;
    }
  }
}

.spa-table-mob-wrap{
  @include rt('small'){
    overflow-x:scroll;
    .spa-table-mob{
      width: 728px;
    }
  }
}