input.form-control{
	border-radius: 30px;
	background-color: #edf4fa;
	color: #051a3b;
	border: 0;
	box-shadow: none;
	padding-left: 40px;
	height: 65px;
}
select.form-control, input.form-control.datepicker{
	background-color: #01102f;
	border-radius: 30px;
	color: #fff;
	height: 65px;
	padding-left: 40px;
}
select.form-control {
	appearance: none;
	background: #01102f url(../images/select_arrow.png) right 27px center no-repeat;
	padding-right: 60px;
}
textarea.form-control{
	background-color: #edf4fa;
	border: none;
	border-radius: 30px;
	padding: 20px;
	resize:none;
}
#webform-client-form-101,
#webform-client-form-327,
#webform-client-form-935
{
	margin-top: 115px;
	.webform-component--imya,
	.webform-component--telefon,
	.webform-component--email{
		margin-top: 33px;
		input{
			width: 94%;
			margin: 0 auto;
			@include rt('medium'){
				width: 100%;
			}
			@include rt('small'){
				width: 100%;
			}
		}
	}
	.webform-component--imya{
		input{
			margin: 0 auto 0 0;
		}
	}
	.webform-component--email{
		input{
			margin: 0 0 0 auto;
		}
	}
	.webform-component--vybor-lecheniya,
	.webform-component--zhelaemaya-data{
		margin-top: 20px;
	}
	.webform-component--komentariy{
		margin-top: 20px;
		margin-bottom: 20px;
	}
	& > div > div{
		@extend .text-center;
	}
	select.form-control, input.form-control.datepicker{
		width: 94%;
		@include rt('medium'){
			width: 100%;
		}
		@include rt('small'){
			width: 100%;
		}
	}
	.form-actions{
		@extend .col-lg-12;
		input{
			width: 240px;
			height: 80px;
			background-color: #ffab30;
			border-color: #ffab30;
			border-radius: 40px;
			box-shadow: 0 4px 0 #e0982b;
			text-transform: uppercase;
			color: #fff;
			outline:0;
			@include rt('small'){
				display: inline-block;
			}
			&:active{
				box-shadow: 0 2px 0 #e0982b;
			}
		}
	}
	.webform-component--top-text,
	.webform-component-markup.webform-component--vopros{
		text-transform: uppercase;
		letter-spacing: 6px;
		line-height: 1;
		.title{
			color: #ffab30;
			font-size: 30px;
		}
		.second-title{
			letter-spacing: 14px;
			margin-top: 2px;
			color: #01102f;
			font-size: 20px;
		}
	}
}


#webform-ajax-wrapper-390 {
	padding-bottom: 60px;
	.alert {
		display: none;
	}
	.t1 {
	    color: #ffab30;
	    font-family: "Open Sans",sans-serif;
	    font-size: 30px;
	    font-weight: 300;
	    text-transform: uppercase;
	    letter-spacing: 7px;
	    text-align: center;
		line-height: 16px;
		padding-top: 101px;
		@include rt('medium'){
			padding-top: 60px;
		}
	}
	.t2 {
	    color: #01102f;
	    font-family: "Open Sans",sans-serif;
	    font-size: 20px;
	    font-weight: 300;
	    text-transform: uppercase;
	    letter-spacing: 16px;
	    margin: 3px 0 0 0px;
	    text-align: center;
	}
	.webform-component--imya {
		float: left;
		width: calc(100% / 3);
		input {
			width: 363px;
		}
		@include rt('medium'){
			width: 100%;
			input {
				width: 100%;
			}
		}
	}
	.webform-component--nomer-telefona {
		float: left;
		width: calc(100% / 3);
		input {
			width: 363px;
			margin-left: 13px;
		}
		@include rt('medium'){
			width: 100%;
			input {
				margin-left: 0;
				width: 100%;
			}
		}
	}
	.form-actions {
		float: left;
		width: calc(100% / 3);
		margin: 0px;
		input[type="submit"] {
			border: 0;
			width: 100%;
			height: 80px;
			padding: 0;
			background-color: #ffab30;
			border-radius: 40px;
			box-shadow: 0 4px 0 #e0982b;
			color: white;
			font-family: "Open Sans",sans-serif;
			font-size: 12px;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 1px;
			height: 60px;
			width: 363px;
			margin-left: 26px;
		}
		@include rt('medium'){
			width: 100%;
			input[type="submit"] {
				margin-left: 0;
				width: 100%;
			}
		}
	}
	.webform-component--zagolovok {
		margin-bottom: 37px;
	}
	form {
		&:after {
			clear: both;
			content: '';
		}
	}
}

.node-webform {
	.alert {
		display: none;
	}
}

#webform-client-form-327>div>div,
#webform-client-form-935>div>div {
    text-align: center;
    padding: 0 10px 0 0;
}

#modal-content,
.block-webform {
	.webform-confirmation {
		text-align: center;
	}
	.links {
		text-align: center; 
	}
}

#modalContent .webform-client-form-366 {
	padding: 115px 0 123px 0;
  @include rt('small'){
    padding: 0px!important;
  }
	.liqpay {
		display: none;
	}
	.webform-client-form{
      > div{
        @include clearfix();
        > .form-item{
          margin: 0 0 40px 0;
        }
      }
    }
    .webform-component--zagolovok{
      text-align: center;
      line-height: 1;
      margin: 0 0 47px 0!important;
      .t1{
        color: #ffab30;
        font-family: $OpenSans;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 7px;
        @include rt('small'){
          font-size: 24px!important;
        }
      }
      .t2{
        color: #01102f;
        font-family: $OpenSans;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 16px;
        margin: 3px 0 0 0px;
        @include rt('small'){
          font-size: 16px!important;
        }
      }
    }
    select,
    input[type="text"],
    input[type="email"]{
      width: 363px;
      height: 60px;
      @include rt('medium'){
        width: 100%!important;
      }
      @include rt('small'){
        width: 100%!important;
      }
    }
    textarea{
      width: 100%;
      height: 120px;
      resize:none;
      border: none;
      box-shadow: none;
    }

    .webform-component--tip-lica,
    .webform-component--e-mail,
    .webform-component--fio,
    .webform-component--data-nachala,
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      width: calc(100% / 3);
      float: left;
      @include rt('medium'){
        width: 100%!important;
        float: none!important;
      }
      @include rt('small'){
        width: 100%!important;
        float: none!important;
      }
    }

    .webform-component--fio,
    .webform-component--data-nachala{
      input{
        margin-left: auto;
        margin-right: auto;
      }
    }
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      input{
        margin-left: auto;
      }
    }
    .webform-component--dopolnitelnye-pozhelaniya{
      clear: both;
    }

    .webform-component--liqpay-i-rekvizity{
      float: right;
      display: flex;
      flex-direction:row;
      margin: 0 249px 0 0!important;
      @include rt('medium'){
        float: none!important;
        margin: 0 0 0 0!important;
        justify-content: center!important;
      }
      @include rt('small'){
        float: none!important;
        margin: 0 0 0 0!important;
        flex-direction:column!important;
        justify-content: flex-start!important;
        align-items:center!important;
      }
      .liqpay{
        a{
          display: block;
          margin: 0 39px 0 0;
          padding: 14px 0 0 0;
          letter-spacing: 1px;
          width: 240px;
          height: 80px;
          border-radius: 40px;
          box-shadow: 0 4px 0 #d8dee3;
          text-align: center;
          background: #edf4fa url('../images/conference/liqpay.jpg') bottom 17px center no-repeat;
          color: #71af2a;
          font-family: $OpenSans;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          @include rt('small'){
            margin: 0 0px 40px 0!important;
          }
        }
      }
      .requisites{
        height: 80px;
        display: flex;
        flex-direction:row;
        align-items:center;
        a{
          color: #ffab30;
          font-family: $OpenSans;
          font-size: 11px;
          font-weight: 700;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }

    .form-actions {
      margin: 0 0 0 246px;
      float: left;
      @include rt('medium'){
        margin: 40px 0 0 0!important;
        float: none!important;
        text-align: center!important;
      }
      @include rt('small'){
        margin: 40px 0 0 0!important;
        float: none!important;
        text-align: center!important;
      }
      input[type="submit"]{
        border:0;
        width: 240px;
        height: 80px;
        padding: 0;
        background-color: #ffab30;
        border-radius: 40px;
        box-shadow: 0 4px 0 #e0982b;
        color: white;
        font-family: $OpenSans;
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1px;
        outline: 0;
        @include rt('medium'){
        }
        @include rt('small'){
          display: inline-block!important;
        }
      }
    }

    .form-number-container {
      background-color: #edf4fa;
      border-radius: 30px;
      height: 60px;
      margin-bottom: 40px;
      padding: 10px 55px 0 40px;
      position: relative;
      width: 363px;
      @include rt('medium'){
        width: 100%!important;
      }
      @include rt('small'){
        width: 100%!important;
        padding: 10px 40px 0 30px!important;
      }
      .form-item {
        margin: 0;
      }
      .form-required {
        color: #051a3b;
      }
      label {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        float: left;
        line-height: 40px;
      }
      input[type=text] {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0.7px 0.7px 0 rgba(0, 0, 0, 0.12);
        color: #051a3b;
        display: inline-block;
        font-size: 15px;
        font-weight: 300;
        float: right;
        height: 40px;
        padding: 0;
        text-align: center;
        width: 40px;
        @include rt('small'){
          width: 39px!important;
        }
      }
      .number-actions {
        height: 36px;
        position: absolute;
        right: 30px;
        top: 13px;
        width: 15px;
        @include rt('small'){
          right: 15px!important;
        }
        .inc,
        .dec {
          background-color: #01102f;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          height: 15px;
          font-size: 20px;
          line-height: 15px;
          text-align: center;
          user-select: none;
          width: 15px;
          &:hover {
            background-color: #ffab30;
          }
        }
        .inc {
          margin-bottom: 6px;
        }
        .dec {
          line-height: 12px;
        }
      }
    }
}
.modal-dialog {
  margin: 0px;
}