.node-devices-teaser {
  margin: 0 auto;
  width: 306px;
  position: relative;
  @include rt('medium'){
  }
  @include rt('small'){
    width: 100%;
    max-width: 306px;
    .field-name-field-image{
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }
  .title-devices {
    background-color: #2dc0f6;
    color: #fff;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    height: 96px;
    line-height: 28px;
    padding: 20px 35px 0 35px;
    text-align: center;
  }
  .open-popup{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    a{
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

#tvm-ajax-popup-wrapper{
  position: fixed;
  top: 50%;
  /*bottom: 0;*/
  left: 0;
  right: 0;
  margin: auto;
  /*top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);*/
  /*height: 650px;*/
  max-height: 90%;
  width: 972px;
  background-color: white;
  z-index: 9999;
  padding: 30px 40px 75px 40px;
  @include rt('medium') {
    width: 768px;
  }
  @include rt('small') {
    width: 320px;
    max-height: 80%;
  }
  .close-popup{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 8px;
    right: 8px;
    background: url(../images/popup/modal_close_btn_sprite.png) 0 0 no-repeat;
    font-size: 0;
    opacity: 1;
    outline: none;
    margin: 0;
    cursor: pointer;
    &:hover{
      background-position: 0 -30px;
    }
  }
  #tvm-ajax-popup{
    max-height: 100%;
    @include rt('small') {
      position: relative;
      max-height: 100%;
    }
    .node-devices{
      .content{
        @include clearfix();
        .field-name-field-image{
          float: left;
          width: 240px;
          text-align: center;
          @include rt('small') {
            float: none;
            width: 100%;
          }
        }
        .field-name-body{
          float: right;
          width: calc(100% - 240px);
          position: relative;
          ul {
            list-style: none;
            padding-left: 0px;
            padding-top: 2px;
            li {
              font-size: 16px;
              font-weight: 400;
              &:before {
                content: '•';
                color: #2dc0f6;
                padding-right: 4px;
              }
            }
          }
          @include rt('small') {
            float: none;
            width: 100%;
          }
          .field-items{
            width: 100%!important;
            padding: 0!important;
            margin: 0!important;
            .field-item{
              position: relative;
              top: 0;
              left: 0!important;
              transform:translateX(0px);
              background-color: transparent;
              max-height: initial;
              padding: 0;
              width: initial;
            }
          }
        }
      }
    }
  }
}

#popup-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  z-index: 9998;
  cursor: pointer;
}

.node-type-deriction {
  .field-name-body {
    >.field-items {
      >.field-item {
        left: 400px!important;
      }
    }
  }
  .pane-node-title {
    h1 {
      width: min-content!important;
    }
  }
}