// Mixins
// -----------------------------------------------------------------------------
@mixin make-container() {
  @include container-fixed();
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
  
  @media (max-width: $screen-md - 1) {
    padding-left: 10px;
    padding-right: 10px;
  }

}

@mixin rt($breakpoint) {
  @if $breakpoint == "small" {
    @media (max-width: $screen-md - 1 )  {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: $screen-md) and (max-width: $screen-lg - 1 ) {
      @content;
    }
  }

  @else if $breakpoint == "sm" {
    @media (max-width: $screen-lg - 1 ) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: $screen-lg) {
      @content;
    }
  }

}
