body.node-type-our-achievements {
	.top_img_text {
		position: relative;
		.field-name-field-image {
			.background-image-formatter {
				height:510px;
				background-size: cover;
				background-position: center;
			}
		}
		.text {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			h1 {
				@include make-container();
				color: #ffab30;
				font-size: 30px;
				font-weight: 300;
				text-transform: uppercase;
				text-align: center;
				font-family: "Open Sans";
				padding-top: 168px;
				letter-spacing: 6px;
				@include rt('medium'){
					padding-top: 140px;
				}
				@include rt('small'){
					padding-top: 115px;
					font-size: 24px;
				}
			}
			.field-name-field-text-after-title {
				@include make-container();
				color: white;
				font-size: 20px;
				font-weight: 300;
				line-height: 30px;
				text-align: center;
				padding: 0px 100px;
				padding-top: 14px;
				@include rt('small'){
					font-size: 16px;
				}
			}
		}
	}
	.paragraphs-items-field-our-achievements {
		@include make-container();
		padding-top: 92px;
    	padding-left: 58px;
    	padding-bottom: 25px;
		.field-name-field-our-achievements {
			& > .field-items {
				& > .field-item {
					padding-bottom: 62px;
				}
			}
		}
		.paragraphs-item-our-achievements {
			.content {
				display: flex;
				@include rt('medium'){
				}
				@include rt('small'){
					flex-direction:column;
					align-items:center;
				}
				.field-name-field-year {
					color: #ffab30;
					font-size: 40px;
					font-weight: 700;
					line-height: 28px;
					width: 159px;
					line-height: 46px;
					@include rt('medium'){
					}
					@include rt('small'){
						width: 100%;
						font-size: 32px;
						text-align: center;
					}
				}
				.field-name-field-text-our-achievements {
					color: #051a3b;
					font-size: 15px;
					font-weight: 300;
					line-height: 28px;
					width: 100%;
					@include rt('medium'){
					}
					@include rt('small'){
						font-size: 14px;
						text-align: center;
					}
				}
			}
		}
	}
	.doctor-certificates {
		padding-top: 105px;
		padding-bottom: 119px;
		.t {
			text-align: center;
			letter-spacing: 1px;
			margin-bottom: 47px;
		}
	}
}