.node-doctor-full {
  .doctor-top-container {
    overflow: hidden;
    position: relative;
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 603px;
      @include rt('medium') {
        height: 302px;
      }
      @include rt('small'){
        height: 102px;
      }
    }
    .doctor-top-text-container {
      left: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      @include rt('medium') {
        background: #1cabf4;
        position: initial;
      }
      @include rt('small'){
        background: #1cabf4;
        position: initial;
      }
      > .container {
        height: 100%;
        position: relative;
      }
      .doctor-top-text-content {
        background: url(../images/doctor_full_info_bg.png) center no-repeat;
        height: 443px;
        left: 50%;
        padding: 70px 60px 0 0;
        position: absolute;
        top: 120px;
        width: 632px;
        @include rt('medium') {
          background: none;
          height: auto;
          left: 0;
          padding: 50px 0;
          position: relative;
          top: 0;
          width: 100%;
        }
        @include rt('small') {
          background: none;
          position: relative;
          left: 0;
          height: auto;
          width: 100%;
          top: 0;
          padding: 50px 0;
        }
      }
      .doctor-fio {
        color: #fff;
        font-family: "Open Sans";
        font-size: 30px;
        font-weight: 300;
        line-height: 36px;
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
      }
      .doctor-post {
        color: #fff;
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
      }
      .doctor-modal {
        text-align: center;
        a {
          color: #fff;
          border: 1px solid white;
          border-radius: 20px;
          display: inline-block;
          font-family: "Open Sans";
          font-size: 11px;
          font-weight: 700;
          height: 40px;
          line-height: 40px;
          outline: none;
          padding: 0 35px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }
}
.doctor-body {
  color: #051a3b;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  padding: 85px 0 70px 0;
  @include rt('medium') {
    padding: 60px 0 50px 0;
  }
  @include rt('small') {
    padding: 60px 0 50px 0;
  }
  p {
    margin-bottom: 30px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 9px;
      &:before {
        content: '';
        background-color: #ffab30;
        border-radius: 50%;
        height: 5px;
        left: 0;
        position: absolute;
        top: 11px;
        width: 5px;
      }
    }
  }
}
.doctor-certificates {
  background-color: #edf4fa;
  padding: 85px 0 90px 0;
  .t {
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  .slick {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
  .slick-slide {
    text-align: center;
    .slick__colorbox {
      height: 262px;
      position: relative;
      width: 262px;
      @include rt('medium'){
        margin: 0 auto;
      }
      @include rt('small'){
        margin: 0 auto;
      }
      &:before {
        background: rgba(45, 192, 246, 0.9) url(../images/zoom_icon.png) center no-repeat;
        content: '';
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &:hover {
        &:before {
          display: block;
        }
      }
    }
    img {
      display: inline-block;
    }
  }
  .slick__arrow {
    display: none;
  }
}
.doctor-webform {
  padding-bottom: 120px;
  .alert {
    display: none;
  }
}

.page-doctors {
  #main {
    background-color: #edf4fa;
  }
}

.node-doctor-teaser {
  .doctor-top-container {
    border-bottom: 3px solid #2dc0f6;
    height: 181px;
    position: relative;
  }
  .background-image-formatter {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 178px;
  }
  .doctor-fio {
    color: #fff;
    font-family: "Open Sans";
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    right: 50px;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
  }
  .field-name-field-doctor-surname {
    font-weight: 700;
  }
  .field-name-field-doctor-name {
    font-weight: 300;
  }
  .field-name-field-doctor-lastname {
    font-weight: 300;
  }
  .content {
    background: #fff;
    height: 308px;
    padding: 52px 52px 0 52px;
    @include rt('medium'){
      height: auto;
      min-height: 342px;
      padding: 40px 25px 40px 25px;
    }
    @include rt('small'){
      height: auto;
      padding: 25px 10px 25px 10px;
    }
  }
  .field-name-field-doctor-post {
    color: #ffab30;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .field-name-field-doctor-preview-text {
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 30px;
    min-height: 90px;
    height: 90px;
    overflow: hidden;
  }
  .more-container {
    a {
      border: 1px solid #051a3b;
      border-radius: 20px;
      color: #767b88;
      display: inline-block;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 700;
      line-height: 40px;
      padding: 0 75px;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        background-color: #2dc0f6;
        border-color: #2dc0f6;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}



.view-id-doctors {
  @extend .container;
  padding-top: 70px;
  .view-header {
    border-bottom: 3px solid #fff;
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 60px;
    text-transform: uppercase;
    @include rt('medium'){
    }
    @include rt('small'){
      font-size: 24px;
      line-height: 52px;
      text-align: center;
    }
  }
  .view-filters {
    position: relative;
    width: 100%;
    @include rt('medium'){
    }
    @include rt('small'){
      margin-top: 30px;
    }
    .views-exposed-form {
      margin: 0;
      position: absolute;
      padding: 0;
      top: -50px;
      right: 0;
      @include rt('medium'){
      }
      @include rt('small'){
        position: relative;
        top: 0;
      }
      label {
        display: none;
      }
      .views-exposed-widget {
        padding: 0;
      }
    }
    #edit-field-doctor-direction-tid-i18n-wrapper{
      @include rt('small'){
        float: none;
      }
    }
    select {
      height: 30px;
      padding-bottom: 0;
      padding-top: 0;
      @include rt('small'){
        margin: 0 auto;
        max-width: 363px;
      }
    }
  }
  .view-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: space-between;
    @include rt('medium'){
    }
    @include rt('small'){
      margin-top: 30px;
    }
    .views-row {
      margin: 20px 0;
      width: 50%;
      @include rt('medium'){
      }
      @include rt('small'){
        width: 100%;
      }
      &.views-row-odd {
        padding-right: 20px;
        @include rt('medium'){
          padding-right: 10px;
        }
        @include rt('small'){
          padding-right: 0px;
        }
      }
      &.views-row-even {
        padding-left: 20px;
        @include rt('medium'){
          padding-left: 10px;
        }
        @include rt('small'){
          padding-left: 0px;
        }
      }
    }
  }
  .text-center {
    .pager-first,
    .pager-last {
      display: none;
    }
    a {
      text-transform: uppercase;
    }
  }
}


.doctors-text-pane {
  padding: 50px 0 80px 0;
  .pane-content {
    @extend .container;
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    p {
      margin-bottom: 30px;
    }
  }
}


.node-doctor-doctorrs-slider {
  overflow: hidden;
  padding-bottom: 124px;
  width: 100%;
  @include rt('medium'){
    padding-bottom: 437px;
  }
  .doctor-top-container {
    position: relative;
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 603px;
      width: 100%;
      @include rt('medium'){
        height: 244px;
      }
      @include rt('small'){
        height: 102px;
      }
    }
    .doctor-top-text-container {
      left: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      @include rt('medium'){

      }
      @include rt('small'){
        position: relative;
        background: #1cabf4;
      }
      > .container {
        height: 100%;
        position: relative;
      }
      .doctor-top-text-content {
        background: url(../images/direction_blue_bg.png) center no-repeat;
        color: #fff;
        height: 593px;
        left: 50%;
        margin-left: -98px;
        padding: 90px 180px 0 120px;
        position: absolute;
        top: 135px;
        width: 784px;
        z-index: 3;
        @include rt('medium'){
          top: 195px;
          left: 0;
          margin-left: 0px;
          padding: 87px 200px 0 154px;
        }
        @include rt('small'){
          background: none;
          left: 0;
          margin-left: 0px;
          padding: 54px 0 0 0;
          position: relative;
          top: 0;
          width: 100%;
          text-align: center;
          height: 555px;
        }
      }
      .doctor-fio {
        color: #fff;
        font-family: "Open Sans";
        font-size: 30px;
        font-weight: 300;
        line-height: 36px;
        margin-bottom: 25px;
        text-transform: uppercase;
        @include rt('medium'){
         margin-bottom: 28px;
        }
        @include rt('small'){
          margin-bottom: 28px;
        }
      }
      .doctor-post {
        color: #fff;
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 25px;
        text-transform: uppercase;
        @include rt('medium'){
          margin-bottom: 21px;
        }
        @include rt('small'){
          margin-bottom: 19px;
        }
      }
      .doctor-desc {
        color: #fff;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 50px;
        @include rt('medium'){
          margin-bottom: 56px;
        }
        @include rt('small'){
          margin-bottom: 30px
        }
      }
      .doctor-modal {
        a {
          color: #fff;
          border: 1px solid white;
          border-radius: 20px;
          display: inline-block;
          font-family: "Open Sans";
          font-size: 11px;
          font-weight: 700;
          height: 40px;
          line-height: 40px;
          outline: none;
          padding: 0 35px;
          text-decoration: none;
          text-transform: uppercase;
          @include rt('medium'){
            padding: 0 42px;
          }
          @include rt('small'){
            padding: 0 42px;
          }
        }
      }
    }
  }
}




