.page-reviews,
.page-otzivihtml  {
	h1 {
		text-align: center;
		color: #2dc0f6;
		font-size: 30px;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 6px;
		line-height: 30px;
		padding-top: 50px;
	}
	.view-reviews {
		@include make-container();
		.view-header {
			text-align: center;
			color: #2dc0f6;
			font-size: 30px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 6px;
			line-height: 30px;
			span {
				color: #01102f;
				font-size: 20px;
				font-weight: 300;
				line-height: 20px;
				text-transform: uppercase;
				display: block;
				letter-spacing: 16px;
				padding-top: 3px;
			}
		}
		.view-content {
			padding-top: 38px;
		    display: flex;
		    justify-content: space-between;
		    flex-wrap:wrap;
		    .views-row {
		    	max-width: 363px;
		    	padding: 4px;
		    	background: white;
		    	position: relative;
		    	margin-bottom: 20px;
		    	img {
		    		width: 100%;
		    		height: auto;
		    	}
		    	&:hover {

		    		.views-field-nothing {
		    			display: flex;
		    		}
		    	}
		    	.views-field-nothing {
		    		display: none;
		    		position: absolute;
		    		background-color: rgba(1, 16, 47, 0.8);
		    		width: 100%;
		    		height: 100%;
		    		left: 0px;
		    		top: 0px;
		    		//display: flex;
		    		justify-content: center;
		    		align-items:center;
		    		a {
		    			color: white;
						font-size: 12px;
						font-weight: 700;
						line-height: 30px;
						text-transform: uppercase;
						width: 220px;
						line-height: 40px;
						background-color: #2dc0f6;
						border-radius: 20px;
						display: inline-block; 
						text-decoration: none;
						text-align: center;
						letter-spacing: 1px;
		    		}
		    	}
		    }
		}
	}
}