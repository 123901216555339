body.node-type-room {
  .big-image{
    position: relative;
    .bi-container {
      .field-name-field-room-title {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        @include rt('medium'){
        }
        @include rt('small'){
          font-size: 14px;
        }
        h1 {
          color: #ffab30;
          font-family: "Open Sans";
          font-size: 30px;
          font-weight: 300;
          letter-spacing: 6px;
          text-transform: uppercase;
          margin-top: 106px;
          margin-bottom: 31px;
          @include rt('medium'){
          }
          @include rt('small'){
            font-size: 24px;
          }
          &.orange {
            color: #ffab30;
          }
        }
      }
      .field-name-field-room-desc {
        margin: 108px 0 0 0;
        width: 680px;
        float: left;
        @include rt('medium'){
          width: 100%;
          float: none;
          margin: 60px 0 0 0;
        }
        @include rt('small'){
          width: 100%;
          float: none;
          margin: 60px 0 0 0;
        }
        .field-items {
          color: #051a3b;
          font-family: "Open Sans";
          font-size: 15px;
          font-weight: 300;
          line-height: 28px;
          @include rt('small'){
            font-size: 14px;
          }
        }
      }
      .price-area {
        background: url(../images/room_blue_bg.png) center no-repeat;
        float: right;
        height: 372px;
        margin: 64px 0 0 0;
        padding: 35px 60px 64px 76px;
        position: relative;
        right: -70px;
        width: 473px;
        @include rt('medium'){
          float: none;
          right: 0px;
          margin: 64px auto 0 auto;
        }
        @include rt('small'){
          float: none;
          right: 0px;
          margin: 64px auto 40px auto;
          padding: 35px 10px 64px 10px;
          width: 100%;
          max-width: 320px;
          background: #00C1F8;
          height: auto;
        }
        .field-name-field-room-price-start {
          color: #fff;
          font-family: "Open Sans";
          font-size: 40px;
          font-weight: 300;
          text-transform: uppercase;
          @include rt('small'){
            font-size: 34px;
          }
        }
        .field-name-field-room-price-included {
          color: #fff;
          font-family: $OpenSans;
          font-size: 12px;
          text-transform: uppercase;
          ul {
            margin: 0;
            padding: 0 0 0 17px;
          }
          li {
            font-size: 15px;
          }
        }
        .bulletproof {
          display: flex;
          flex-direction:row;
          align-items:center;
          justify-content:center;
          width: 180px;
          height: 40px;
          border: 3px solid white;
          border-radius: 20px;
          margin: 31px 0 0 0px;
          a{
            color: white;
            font-family: $OpenSans;
            font-size: 11px;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .img-gallery {
    padding: 0 0 60px 0;
    .paragraphs-items-field-mc-gallery{
      > .field-name-field-mc-gallery{
        > .field-items{
          @include rt('medium') {
            margin: 0 auto;
            width: 584px;
          }
          > .field-item{
            float: left;
            margin-bottom: 1px;
            margin-right: 1px;
            @include rt('medium') {
              &:nth-child(3),
              &:nth-child(n+5) {
                display: none;
              }
            }
            .paragraphs-item-pb-image-image-style{
              a{
                display: block;
                position: relative;
                &:hover{
                  &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(45,192,246,.9) url('../images/search.png') center no-repeat;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .room-equip {
    background: url(../images/room_equip_bg.jpg) center no-repeat;
    background-size: cover;
    padding: 103px 0 115px 0;
    @include rt('small'){
      padding: 70px 0 82px 0;
    }
    .field-name-field-room-equip {
      .field-label {
        color: white;
        font-family: "Open Sans";
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        text-transform: uppercase;
        @include rt('small'){
          font-size: 26px;
        }
      }
      > .field-items {
        display: flex;
        flex-direction:row;
        flex-wrap:wrap;
        margin: 64px 0 0 0;
        > .field-item {
          margin-bottom: 67px;
          width: 25%;
          @include rt('medium'){
            width: 50%;
          }
          @include rt('small'){
            width: 100%;
          }
          .taxonomy-term {
            display: flex;
            flex-direction:column;
            align-items:center;
            .field-name-field-room-equip-icon {
              height: 75px;
              position: relative;
              text-align: center;
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 42px;
                height: 8px;
                background-color: #cad7e3;
              }
            }
            .taxonomy-term-description {
              color: #fff;
              font-family: "Open Sans";
              font-size: 11px;
              font-weight: 700;
              line-height: 20px;
              text-align: center;
              text-transform: uppercase;
              margin: 25px 0 0 0;
            }
          }
        }
      }
    }
  }

  .room-prices {
    background-color: #edf4fa;
    padding: 110px 0 50px 0;
  }
  .field-name-field-room-prices {
    > .field-items {
      @extend .container;
    }
  }
  .field-name-field-room-prices-title {
    margin-bottom: 65px;
    text-align: center;
    .t1 {
      color: #ffab30;
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 5px;
      line-height: 40px;
      text-transform: uppercase;
      @include rt('small'){
        font-size: 24px;
        line-height: 32px;
      }
    }
    .t2 {
      color: #01102f;
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 10px;
      line-height: 20px;
      text-transform: uppercase;
      @include rt('small'){
        font-size: 16px;
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }
  .paragraphs-items-field-room-prices-items {
    .field-name-field-room-prices-items {
      > .field-items {
        > .field-item {
          margin-bottom: 70px;
        }
      }
    }
  }
  .field-name-field-rpi-title {
    border-bottom: 4px solid #fff;
    color: #01102f;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    padding: 5px 0;
    @include rt('small'){
      font-size: 20px;
    }
  }
  .field-name-field-rpi-table {
    @include rt('small'){
      overflow-x:scroll;
      .field-items{
        width: 728px;
      }
    }
    table {
      border-spacing: 4px;
      border-collapse: separate;
      thead {
        border: none;
      }
      tbody {
        border: none;
      }
      tr {
        border: none;
        th {
          background-color: #01102f;
          border: none;
          color: #fff;
          font-family: "Open Sans";
          font-size: 12px;
          font-weight: 700;
          text-align: center;
          vertical-align: middle;
          &.category {
            padding-left: 35px;
            padding-right: 35px;
            text-align: left;
          }
          &.quantity {
            width: 102px;
          }
          &.price {
            width: 148px;
          }
          &.place {
            width: 234px;
          }
        }
        td {
          background-color: #fff;
          border: none;
          color: #051a3b;
          font-family: "Open Sans";
          font-size: 15px;
          font-weight: 300;
          line-height: 28px;
          text-align: center;
          &.category {
            padding-left: 35px;
            padding-right: 35px;
            text-align: left;
          }
          &.price {
            background-color: #FFAB2F;
            color: #fff;
          }
          &.place {
            background-color: #768FA5;
            color: #fff;
          }
        }
      }
    }
  }

  .webform-bulletproof {
    .liqpay {
      display: none;
    }
    padding: 115px 0 123px 0;
    .webform-client-form{
      > div{
        @include clearfix();
        > .form-item{
          margin: 0 0 40px 0;
        }
      }
    }
    .webform-component--zagolovok{
      text-align: center;
      line-height: 1;
      margin: 0 0 47px 0!important;
      .t1{
        color: #ffab30;
        font-family: $OpenSans;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 7px;
        @include rt('small'){
          font-size: 24px;
        }
      }
      .t2{
        color: #01102f;
        font-family: $OpenSans;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 16px;
        margin: 3px 0 0 0px;
        @include rt('small'){
          font-size: 16px;
        }
      }
    }
    select,
    input[type="text"],
    input[type="email"]{
      width: 363px;
      height: 60px;
      @include rt('medium'){
        width: 100%;
      }
      @include rt('small'){
        width: 100%;
      }
    }
    textarea{
      width: 100%;
      height: 120px;
      resize:none;
      border: none;
      box-shadow: none;
    }

    .webform-component--tip-lica,
    .webform-component--e-mail,
    .webform-component--fio,
    .webform-component--data-nachala,
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      width: calc(100% / 3);
      float: left;
      @include rt('medium'){
        width: 100%;
        float: none;
      }
      @include rt('small'){
        width: 100%;
        float: none;
      }
    }

    .webform-component--fio,
    .webform-component--data-nachala{
      input{
        margin-left: auto;
        margin-right: auto;
      }
    }
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      input{
        margin-left: auto;
      }
    }
    .webform-component--dopolnitelnye-pozhelaniya{
      clear: both;
    }

    .webform-component--liqpay-i-rekvizity{
      float: right;
      display: flex;
      flex-direction:row;
      margin: 0 249px 0 0!important;
      @include rt('medium'){
        float: none;
        margin: 0 0 0 0!important;
        justify-content: center;
      }
      @include rt('small'){
        float: none;
        margin: 0 0 0 0!important;
        flex-direction:column;
        justify-content: flex-start;
        align-items:center;
      }
      .liqpay{
        a{
          display: block;
          margin: 0 39px 0 0;
          padding: 14px 0 0 0;
          letter-spacing: 1px;
          width: 240px;
          height: 80px;
          border-radius: 40px;
          box-shadow: 0 4px 0 #d8dee3;
          text-align: center;
          background: #edf4fa url('../images/conference/liqpay.jpg') bottom 17px center no-repeat;
          color: #71af2a;
          font-family: $OpenSans;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          @include rt('small'){
            margin: 0 0px 40px 0;
          }
        }
      }
      .requisites{
        height: 80px;
        display: flex;
        flex-direction:row;
        align-items:center;
        a{
          color: #ffab30;
          font-family: $OpenSans;
          font-size: 11px;
          font-weight: 700;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }

    .form-actions {
      margin: 0 0 0 246px;
      float: left;
      @include rt('medium'){
        margin: 40px 0 0 0;
        float: none;
        text-align: center;
      }
      @include rt('small'){
        margin: 40px 0 0 0;
        float: none;
        text-align: center;
      }
      input[type="submit"]{
        border:0;
        width: 240px;
        height: 80px;
        padding: 0;
        background-color: #ffab30;
        border-radius: 40px;
        box-shadow: 0 4px 0 #e0982b;
        color: white;
        font-family: $OpenSans;
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1px;
        outline: 0;
        @include rt('medium'){
        }
        @include rt('small'){
          display: inline-block;
        }
      }
    }

    .form-number-container {
      background-color: #edf4fa;
      border-radius: 30px;
      height: 60px;
      margin-bottom: 40px;
      padding: 10px 55px 0 40px;
      position: relative;
      width: 363px;
      @include rt('medium'){
        width: 100%;
      }
      @include rt('small'){
        width: 100%;
        padding: 10px 40px 0 40px;
      }
      .form-item {
        margin: 0;
      }
      .form-required {
        color: #051a3b;
      }
      label {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        float: left;
        line-height: 40px;
      }
      input[type=text] {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0.7px 0.7px 0 rgba(0, 0, 0, 0.12);
        color: #051a3b;
        display: inline-block;
        font-size: 15px;
        font-weight: 300;
        float: right;
        height: 40px;
        padding: 0;
        text-align: center;
        width: 40px;
        @include rt('small'){
          width: 39px;
        }
      }
      .number-actions {
        height: 36px;
        position: absolute;
        right: 30px;
        top: 13px;
        width: 15px;
        @include rt('small'){
          right: 15px;
        }
        .inc,
        .dec {
          background-color: #01102f;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          height: 15px;
          font-size: 20px;
          line-height: 15px;
          text-align: center;
          user-select: none;
          width: 15px;
          &:hover {
            background-color: #ffab30;
          }
        }
        .inc {
          margin-bottom: 6px;
        }
        .dec {
          line-height: 12px;
        }
      }
    }

  }

  .epilogue {
    background-color: #edf4fa;
    padding: 0px 0 0px 0;
    .field-name-body {
      color: #051a3b;
      font-family: $OpenSans;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.92;
    }
  }

}





