.node-type-deriction{
	.pane-node-field-body-3-screen{
		margin-top: 80px;
		color: #051a3b;
		font-size: 15px;
	}
}
#panel-bootstrap-region-bgblue{
	padding-bottom: 110px;
}

.pane-node-field-devices{
	.pane-content{
		width: 90%;
		margin: 0 auto;
		position: relative;
	}
	.owl-item{
		// @extend .text-center;
		&>div{
			width: 306px;
			height: 342px;
			margin: 0 auto;
		}
		&.active:nth-child(1)>div{
			// margin: 0 0 0 auto;
		}
		&.active:nth-child(3)>div{
			// margin: 0 auto 0 0;
		}
		.title-devices{
			color: #fff;
			background: #2dc0f6;
			font-size: 15px;
			line-height: 28px;
			height: 95px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.owl-nav{
		.owl-next,
		.owl-prev{
			position: absolute;
			top: 150px;
			color: #2dc0f6;
			background: none;
			font-size: 40px;
			line-height: 1;
		}
		.owl-prev{
			left: -20px;
		}
		.owl-next{
			right: -20px;
		}
	}	

}