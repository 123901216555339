.pane-treatment-stages {
  background-color: #edf4fa;
  padding: 110px 0 120px 0;
  > .pane-content {
    @include make-container();
  }
}
#mini-panel-treatment_stages {
  .panel-col-top {
    .pane-content {
      margin-bottom: 40px;
      text-align: center;
    }
    .t1 {
      color: #2dc0f6;
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 3px;
      line-height: 30px;
      text-transform: uppercase;
      @include rt('small'){
        font-size: 24px;
      }
    }
    .t2 {
      color: #01102f;
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 5px;
      line-height: 20px;
      text-transform: uppercase;
      @include rt('small'){
        font-size: 18px;
      }
    }
  }
  .panel-col-middle {
    .inside {
      display: flex;
      justify-content: space-between;
      @include rt('medium'){
        flex-wrap:wrap;
        justify-content: space-around;
      }
      @include rt('small'){
        flex-wrap:wrap;
        justify-content:flex-start;
        align-items:center;
        flex-direction:column;
      }
    }
    .pane-treatment-stage {
      cursor: unset;
      position: relative;
      width: 262px;
      @include rt('medium'){
        margin-bottom: 25px;
      }
      @include rt('small'){
        margin-bottom: 25px;
      }
      &:hover {
        .field-name-field-treatment-stage-title {
          background: url(../images/treatment_item_bg_hover.png) center no-repeat;
        }
        .field-name-field-treatment-stage-text {
          font-weight: 700;
        }
      }
    }
    .field-name-field-treatment-stage-image {
      border-radius: 50%;
      overflow: hidden;
    }
    .field-name-field-treatment-stage-title {
      background: url(../images/treatment_item_bg.png) center no-repeat;
      color: #fff;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      left: 50%;
      height: 54px;
      letter-spacing: 2px;
      line-height: 54px;
      position: absolute;
      text-align: center;
      top: 151px;
      transform: translateX(-50%);
      width: 260px;
      @include rt('small'){
        font-size: 16px;
      }
    }
    .field-name-field-treatment-stage-text {
      color: #051a3b;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 300;
      line-height: 30px;
      margin-top: 35px;
      text-align: center;
      @include rt('small'){
        font-size: 14px;
      }
    }
  }
  .pane-multiblock-2 {
    margin-top: 50px;
    .pane-content {
      text-align: center;
      a {
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0 4px 0 #e4eaf0;
        color: #2dc0f6;
        display: inline-block;
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 700;
        height: 80px;
        line-height: 14px;
        outline: none;
        padding: 30px 50px;
        text-decoration: none;
        text-transform: uppercase;
        width: 300px;
      }
    }
  }
}

.flex-4 {
  display: flex;
  margin: 40px 0 45px 0;
  justify-content: space-between;
  @include rt('medium'){
    flex-wrap:wrap;
    justify-content: space-around;
    > *{
      margin-bottom: 10px;
    }
  }
  @include rt('small'){
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    > *{
      margin-bottom: 10px;
    }
  }
}
#mini-panel-disease_top_block {
  position: relative;
}
#mini-panel-disease_text_bg_block {
  position: relative;
}
.node-type-spa-tour,
.node-type-disease {
  #header {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include rt('medium'){
      position: relative;
    }
    @include rt('small'){
      position: relative;
    }
    .bottom-part {
      background-color: rgba(1,16,47,.7);
    }
  }
  #page-header {
    left: 0;
    position: absolute;
    top: 140px;
    width: 100%;
    z-index: 2;
  }
  .field-name-field-disease-top-bg {
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 650px;
      @include rt('small') {
        height: 140px;
      }
    }
  }
  .pane-node-field-bg-mobile-image {
    img {
      width: 100%;
      height: auto;
    }

    .background-image-formatter-mobile {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;
      height: 650px;
    }
  }
  .pane-node-title {
    bottom: 330px;
    left: 0;
    position: absolute;
    width: 100%;
    @include rt('medium'){
      bottom: 388px;
    }
    @include rt('small'){
      //bottom: 450px;
      position: relative;
      top: 0px;
      text-align: center;
    }
    .pane-content {
      @include make-container();
    }
    h1 {
      color: #ffab30;
      font-family: "Open Sans";
      font-size: 40px;
      font-weight: 300;
      max-width: 550px;
      @include rt('small'){
        font-size: 40px;
        margin: 30px 0px;
        width: 100%;
        max-width: inherit;
      }
    }
  }
  .field-name-field-disease-top-text {
    left: 0;
    position: absolute;
    top: 354px;
    width: 100%;
    @include rt('medium'){
      top: 316px;
    }
    @include rt('small'){
      text-align: center;
      //top: 215px;
      position: relative;
      top: 0px;
      padding-bottom: 30px;
    }
    > .field-items {
      @include make-container();
      > .field-item {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        max-width: 550px;
        @include rt('small'){
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
  .field-name-field-disease-bg {
    @include rt('medium'){
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%
    }
    @include rt('small'){
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%
    }
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 561px;
      @include rt('medium'){
        width: 100%;
        height: 100%;
        position: absolute;
      }
      @include rt('small'){
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
  .field-name-field-disease-text-bg {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    @include rt('medium'){
      position: relative;
      top: 0;
      transform:none;
      padding: 50px 0;
      min-height: 561px;
      display: flex;
      flex-direction:row;
      align-items:center;
    }
    @include rt('small'){
      position: relative;
      top: 0;
      transform:none;
      padding: 50px 0;
      min-height: 561px;
      display: flex;
      flex-direction:row;
      align-items:center;
    }
    > .field-items {
      @include make-container();
      > .field-item {
        color: #fff;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        @include rt('small'){
          font-size: 14px;
          text-align: center;
        }
        strong {
          font-weight: 700;
        }
        p {
          margin-bottom: 25px;
        }
      }
    }
  }
  .field-name-body {
    padding: 80px 0;
    > .field-items {
      @include make-container();
      > .field-item {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        @include rt('small'){
          font-size: 14px;
          text-align: center;
        }
        .t1 {
          color: #2dc0f6;
          font-family: "Open Sans";
          font-size: 30px;
          font-weight: 300;
          letter-spacing: 3px;
          line-height: 30px;
          text-align: center;
          text-transform: uppercase;
          @include rt('small'){
            font-size: 24px;
          }
        }
        .t2 {
          color: #01102f;
          font-family: "Open Sans";
          font-size: 20px;
          font-weight: 300;
          letter-spacing: 5px;
          line-height: 20px;
          margin-bottom: 30px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 16px;
          @include rt('small'){
            font-size: 16px;
            letter-spacing: 14px;
          }
        }
        blockquote {
          border-left: 41px solid #2dc0f6;
          color: #051a3b;
          font-family: Georgia;
          font-size: 17px;
          font-style: italic;
          padding: 6px 0 10px 24px;
          margin: -5px 0 36px 13px;
          line-height: 1.888;
          @include rt('small'){
            border-left: 15px solid #2dc0f6;
            font-size: 16px;
            padding: 6px 0 10px 10px;
            margin: -5px 0 36px 0px;
          }
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
  .pane-node-field-spa-inside-program {
    background: #edf4fa;
    padding-top: 88px;
    padding-bottom: 34px;
    .paragraphs-items-field-spa-inside-program {
        @include make-container();
        .field-name-field-inside-spa {
          text-align: center;
          color: #01102f;
          font-size: 20px;
          font-weight: 300;
          line-height: 26px;
          margin-bottom: 35px;
          text-transform: uppercase;
          letter-spacing: 16px;
          .blue {
            color: #2dc0f6;
            font-size: 30px;
            font-weight: 300;
            letter-spacing: 6px;
            text-transform: uppercase;
          }
        }
    }
    .field-name-field-title-1 {
      float: left;
      width: calc(100% - 234px);
      border-right: 4px solid #edf4fa;
      border-bottom: 4px solid #edf4fa;
      background: #01102f;
      color: white;
      font-size: 12px;
      font-weight: 700;
      line-height: 49px;
      padding-left: 38px;
    }
    .field-name-field-title-2 {
      float: right;
      width: 234px;
      background: #01102f;
      color: white;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      line-height: 49px;
      border-bottom: 4px solid #edf4fa;
    }
    .paragraphs-items-field-spa-inside {
      clear: both;
      .field-name-field-first-field {
        float: left;
        width: calc(100% - 234px);
        border-right: 4px solid #edf4fa;
        border-bottom: 4px solid #edf4fa;
        background: #ffffff;
        padding-left: 38px;
        color: #051a3b;
        font-size: 15px;
        font-weight: 400;
        line-height: 27px;
        padding-top: 9px;
        padding-bottom: 9px;
      }
      .field-name-field-second-field {
        float: right;
        width: 234px;
        background: #d5e0e4;
        text-align: center;
        border-bottom: 4px solid #edf4fa;
        color: #051a3b;
        font-size: 15px;
        font-weight: 300;
        line-height: 27px;
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
  }
}

.view-id-disease_doctors {
  margin-top: 120px;
  margin-bottom: -124px;
  position: relative;
  z-index: 2;
  width: 100%;
  @include rt('medium'){
    margin-bottom: 0px;
  }
  .slick-arrow {
    border: none;
    bottom: 154px;
    font-size: 0;
    height: 35px;
    outline: none;
    text-indent: -999999px;
    position: absolute;
    width: 18px;
    z-index: 5;
    @include rt('medium'){
      bottom: 44px;
    }
    @include rt('small'){
      bottom: 184px;
    }
    &.slick-disabled {
      opacity: .5;
    }
  }
  .node-doctor-doctorrs-slider{
    @include rt('medium'){
      overflow: visible;
    }
  }
  .slick-list{
    @include rt('medium'){
      overflow: visible;
    }
  }
  .slick__arrow {
    @include make-container();
    position: relative;
  }
  .slick-prev {
    background: url(../images/doctors_slider_prev.png) center no-repeat;
    right: 160px;
    @include rt('medium'){
      right: 203px;
    }
    @include rt('small'){
      left: calc((100% - 86px) / 2);
      right: auto;
    }
  }
  .slick-next {
    background: url(../images/doctors_slider_next.png) center no-repeat;
    right: 92px;
    @include rt('medium'){
     right: 135px;
    }
    @include rt('small'){
      right: calc((100% - 86px) / 2);
    }
  }
}

.node-type-disease {
  .pane-node-field-disease-price-title,
  .pane-3 {
    background: #edf4fa;
    .pane-content {
      @include make-container();
      text-align: center;
      color: #01102f;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      text-transform: uppercase;
      padding-top: 118px;
      padding-bottom: 20px;
      letter-spacing: 16px;
      @include rt('small'){
        font-size: 16px;
        letter-spacing: 10px;
      }
      .orange {
        letter-spacing: 6px;
        color: #ffab30;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        @include rt('small'){
          font-size: 24px;
        }
      }
    }
  }
  .pane-node-field-disease-price {
    background: #edf4fa;
    padding-top: 45px;
    padding-bottom: 45px;
    & > .pane-content {
      @include make-container();
      .field-name-field-service-procedure-name {
        color: #051a3b;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        padding: 16px 0px;
        background-color: white;
        border-radius: 30px;
        padding-left: 49px;
        padding-right: 90px;
        position: relative;
        cursor: pointer;
        @include rt('small'){
          padding-left: 20px;
          padding-right: 49px;
        }
        &:after {
          content: '';
          width: 35px;
          height: 35px;
          position: absolute;
          top: 50%;
          right: 49px;
          margin-top: -17.5px;
          background: url('../images/disease_plus.png');
          @include rt('small'){
            right: 10px;
          }
        }
        &.opened {
          color: white;
          background: #768fa5;
          border-radius: 30px 30px 0px 0px;
          &:after {
            background: url('../images/disease_minus.png');
          }
        }
      }
      .paragraphs-items-field-service-procedure-prices {
        padding-top: 4px;
        display: none;
        @include rt('small'){
          overflow-x:scroll;
          > .field-name-field-service-procedure-prices{
            width: 728px;
          }
        }
      }
      .paragraphs-item-service-prices {
        & > .content {
          display: flex;
          flex-wrap: wrap;
          .paragraphs-items-field-p-service-price {
            flex:0 0 100%;
          }
          .field-name-field-column-name-1 {
            width: calc(100% - 476px);
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            padding-left: 38px;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-2 {
            width: 234px;
            background: #01102f;
            margin-right: 4px;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
          .field-name-field-column-name-3 {
            width: 234px;
            background: #01102f;
            color: white;
            font-size: 12px;
            font-weight: 700;
            padding: 14px 0px;
            text-align: center;
            span {
              color: #ffab30;
            }
          }
        }
      }
      .paragraphs-item-service-price-item {
      	margin-top: 4px;
      	& > .content {
          display: flex;
          flex-wrap: wrap;
          .field-name-field-column1 {
            width: calc(100% - 476px);
            background: white;
            margin-right: 4px;
            padding: 11px 0px;
            padding-left: 38px;
            color: #05193b; /* text color */
		        font-size: 15px;
            &.summary-row {
              background: #efddc2;
              color: #95784d;
            }
          }
          .field-name-field-column2 {
            width: 234px;
            background: #d5e0e4;
            margin-right: 4px;
            padding: 11px 0px;
            text-align: center;
            color: #05193b; /* text color */
			      font-size: 15px;
            &.summary-row {
              background: #ffab30;
              color: #fff;
            }
          }
          .field-name-field-column3 {
            width: 234px;
            background: #768fa5;
            padding: 11px 0px;
            text-align: center;
            color: white; /* text color */
			      font-size: 15px;
            &.summary-row {
              background: #ffab30;
              color: #fff;
            }
          }
        }
      }
      .field-name-field-p-service-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 4px;
      		}
      	}
      }
      .field-name-field-disease-price {
      	&>.field-items {
      		&>.field-item {
      			margin-bottom: 60px;
      		}
      	}
      }
    }
  }
}

.page-node-438 {
  .field-name-field-disease-top-bg .background-image-formatter {
    @include rt('small'){
      background-position: 62% 0px;
    }
  }
}

.page-node-556 {
  .field-name-field-disease-top-bg .background-image-formatter {
    @include rt('small'){
      background-position: 72% 0px;
    }
  }
}

@include rt('small'){
  .node-type-disease .pane-node-title {
    position: inherit;
    bottom: 0px;
    text-align: center;
    h1 {
      margin: 30px 0px;
      width: 100%;
      max-width: inherit;
    }
  }
  .node-type-disease .field-name-field-disease-top-text {
    position: inherit;
    top: 0px;
    &>.field-items>.field-item {
      max-width: 100%;
      padding-bottom: 30px;
    }
  }
}
.node-type-disease { 

  .pane-node-field-disease-top-bg {
    display: block;
    
    @include rt('small') {
      //display: none;

	    .background-image-formatter {
	    	height: 140px;
	    }

    }
    &.for_mobile {
      display: none;
      img {
        width: 100%;
        height: auto;
      }
      @include rt('small') {
        //display: block;
      }
    }
  }
}