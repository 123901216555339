.node-spa-tour-teaser {
  margin: 20px auto 0 auto;
  transition: all 0.2s ease-in-out;
  width: 262px;
  position: relative;
  &:hover {
    transform: translateY(-20px);
    .field-name-field-spa-tour-slider-image {
      box-shadow: 0 22px 22px rgba(0, 0, 0, 0.12);
    }
    .title-container {
      a {
        background: url(../images/treatment_item_bg.png) center no-repeat;
      }
    }
  }
  .field-name-field-spa-tour-slider-image {
    border-radius: 50%;
    height: 262px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    width: 262px;
  }
  .title-container {
    position: absolute;
    top: 150px;
    width: 100%;
    a {
      background: url(../images/treatment_item_bg_hover.png) center no-repeat;
      color: #fff;
      display: block;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 300;
      height: 54px;
      line-height: 54px;
      margin: 0 auto;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      width: 260px;
    }
  }
  .field-name-field-spa-tour-slider-text {
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 43px;
    text-align: center;
  }
}