#page-wrapper {
  overflow: hidden;
  width: 100%;
}
.node-type-deriction {
  #panel-bootstrap-region-center {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .pane-node-field-bg-image {
    display: block;
    @include rt('small') {
      display: none;
    }
    &.for_mobile {
      display: none;
      img {
        width: 100%;
        height: auto;
      }
      @include rt('small') {
        display: block;
      }
    }
  }
  .field-name-field-bg-image {
    
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 947px;
      @include rt('small') {
        height: 182px;
      }
    }
  }

  .field-name-field-bg-mobile-image {
    img {
      width: 100%;
      height: auto;
    }
    .background-image-formatter {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;
      @include rt('small') {
        height: 182px;
      }
    }
  }
  .pane-node-title {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    @include rt('small') {
      /*top: 91px;
      transform: translateY(-50%);*/
      position: relative;
      top: 0px;
      margin-top: 45px;
    }
    .pane-content {
      @extend .container;
      padding-top: 136px;
      @include rt('small') {
        padding: 0;
      }
    }
    h1 {
      background-color: #ffab30;
      color: #fff;
      font-family: "Open Sans";
      font-size: 40px;
      font-weight: 300;
      margin: 0;
      padding: 23px 40px;
      text-transform: uppercase;
      width: 359px;
      @include rt('medium'){
        margin-left: auto;
        margin-right: auto;
      }
      @include rt('small') {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding: 16px 29px;
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .field-name-body {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include rt('small') {
      position: initial;
    }
    > .field-items {
      @extend .container;
      position: relative;
      > .field-item {
        background-color: rgba(255,255,255,.9);
        left: 319px;
        max-height: 433px;
        overflow: hidden;
        padding: 70px 80px;
        position: absolute;
        top: 96px;
        width: 652px;
        @include rt('medium'){
          top: 226px;
          left: 50%;
          transform:translateX(-400px);
        }
        @include rt('small') {
          color: #051a3b;
          font-size: 15px;
          font-weight: 300;
          max-height: none;
          position: initial;
          padding: 50px 0 30px 0;
          width: 100%;
        }
      }
    }
  }
  .field-name-field-body-2-screen {
    background-color: rgba(255,255,255,.5);
    height: 419px;
    left: 0;
    position: absolute;
    top: 528px;
    width: 100%;
    z-index: 2;
    @include rt('small') {
      height: auto;
      position: relative;
      top: 0;
    }
    > .field-items {
      @extend .container;
      .field-item {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        height: 419px;
        line-height: 28px;
        overflow: hidden;
        padding: 80px 100px 0 0;
        width: 50%;
        @include rt('medium'){
          width: 100%;
          padding: 50px 0;
        }
        @include rt('small') {
          color: #051a3b;
          font-size: 15px;
          font-weight: 300;
          height: auto;
          padding: 0 0 40px 0;
          width: 100%;
        }
      }
    }
  }
  .field-name-field-what-we-treat {
    background: url(../images/direction_blue_bg.png) center no-repeat;
    color: #fff;
    height: 593px;
    left: 50%;
    margin-left: -20px;
    padding: 43px 180px 0 120px;
    position: absolute;
    top: 463px;
    width: 784px;
    z-index: 3;
    @include rt('medium'){
      position: relative;
      top: 0;
      margin: 0 auto 0 auto;
      left: 20px;
    }
    @include rt('small'){
    }
    h2 {
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        background: url(../images/heart_icon.png) left top no-repeat;
        list-style: none;
        margin-bottom: 20px;
        min-height: 30px;
        padding-left: 56px;
        color: #fff;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  .pane-what-we-treat {
    background: url(../images/direction_blue_bg.png) center no-repeat;
    color: #fff;
    height: 593px;
    left: 50%;
    margin-left: -20px;
    padding: 43px 180px 0 120px;
    position: absolute;
    top: 463px;
    width: 784px;
    z-index: 3;
    @include rt('medium'){
      position: relative;
      top: 0;
      margin: 40px auto 0 auto;
      left: 20px;
    }
    @include rt('small'){
      position: relative;
      top: 0;
      margin: 25px 10px 0 10px;
      left: 0px;
      width: initial;
      height: auto;
      padding: 30px 10px 30px 10px;
      background: #00C1F8;
    }
    .view-header {
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .view-content ul {
      margin: 0;
      padding: 0;
      li {
        background: url(../images/heart_icon.png) left top no-repeat;
        list-style: none;
        margin-bottom: 20px;
        min-height: 30px;
        padding-left: 56px;
        margin-left: 0px;
        a {
          color: #fff;
          font-family: "Open Sans";
          font-size: 18px;
          font-weight: 300;
          text-decoration: none;
        }
      }
    }
  }
  .pane-node-field-body-3-screen {
    margin: 0 !important;
  }
  .field-name-field-body-3-screen {
    padding: 110px 0 80px 0;
    @include rt('medium'){
      padding: 0px 0 50px 0;
    }
    @include rt('small'){
      padding: 50px 0;
    }
    > .field-items {
      @extend .container;
      color: #051a3b;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 300;
      line-height: 28px;
    }
  }
  .field-name-field-text-after-video {
    color: #051a3b;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    margin-top: 85px;
    @include rt('medium'){
    }
    @include rt('small'){
      margin-top: 40px;
    }
  }
}
.view-id-direction_doctors {
  margin-top: 120px;
  margin-bottom: -124px;
  position: relative;
  z-index: 2;
  width: 100%;
  @include rt('medium'){
    margin-bottom: 0px;
  }
  .slick-arrow {
    border: none;
    bottom: 154px;
    font-size: 0;
    height: 35px;
    outline: none;
    text-indent: -999999px;
    position: absolute;
    width: 18px;
    z-index: 5;
    @include rt('medium'){
      bottom: 44px;
    }
    @include rt('small'){
      bottom: 184px;
    }
    &.slick-disabled {
      opacity: .5;
    }
  }
  .node-doctor-doctorrs-slider{
    @include rt('medium'){
      overflow: visible;
    }
  }
  .slick-list{
    @include rt('medium'){
      overflow: visible;
    }
  }
  .slick__arrow {
    @extend .container;
    position: relative;
  }
  .slick-prev {
    background: url(../images/doctors_slider_prev.png) center no-repeat;
    right: 160px;
    @include rt('medium'){
      right: 203px;
    }
    @include rt('small'){
      left: calc((100% - 86px) / 2);
      right: auto;
    }
  }
  .slick-next {
    background: url(../images/doctors_slider_next.png) center no-repeat;
    right: 92px;
    @include rt('medium'){
     right: 135px;
    }
    @include rt('small'){
      right: calc((100% - 86px) / 2);
    }
  }
}


.view-id-direction_devices {
  @extend .container;
  padding: 100px 0 40px 0;
  @include rt('small'){
    padding: 100px 0 40px 0!important;
  }
  .view-header {
    margin-bottom: 30px;
    text-align: center;
    .t1 {
      color: #2dc0f6;
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 3px;
      line-height: 30px;
      text-transform: uppercase;
    }
    .t2 {
      color: #01102f;
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 5px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .slick {
    padding: 0 65px;
    position: relative;
    @include rt('medium'){
      padding: 0 20px
    }
    @include rt('small'){
      padding: 0 28px;
    }
  }
  .slick-arrow {
    border: none;
    font-size: 0;
    height: 27px;
    outline: none;
    position: absolute;
    text-indent: -99999px;
    top: 132px;
    width: 14px;
    &.slick-disabled {
      opacity: .5;
    }
    @include rt('small') {
      height: 71px;
      top: 88px;
      width: 37px;
    }
  }
  .slick-prev {
    background: url(../images/blue_arrow_left.png) center no-repeat;
    left: 0;
    @include rt('small') {
      background: url(../images/mobile_arrow_left.png) center no-repeat;
    }
  }
  .slick-next {
    background: url(../images/blue_arrow_right.png) center no-repeat;
    right: 0;
    @include rt('small') {
      background: url(../images/mobile_arrow_right.png) center no-repeat;
    }
  }
}

.pane-node-field-youtube{
  @include rt('medium'){
    &.pad-85{
      padding: 0 40px;
    }
  }
  @include rt('small'){
    &.pad-85{
      padding: 0 10px;
    }
  }
}

.pane-node-field-text-after-video{
  @include rt('medium'){
    &.pad-85{
      padding: 0 40px;
    }
  }
  @include rt('small'){
    &.pad-85{
      padding: 0 10px;
    }
  }
}