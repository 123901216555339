.page-spa-prices {
  #main {
    background-color: #edf4fa;
  }
}
.spa-prices-top-pane {
  padding: 90px 0 35px 0;
  .pane-content {
    @extend .container;
    color: #051a3b;
    font-size: 15px;
    text-align: center;
    @include rt('small'){
      font-size: 14px;
    }
    h1 {
      color: #ffab30;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 5px;
      text-transform: uppercase;
      @include rt('small'){
        font-size: 20px;
      }
    }
  }
}
.view-id-spa_tours_prices {
  margin-bottom: 90px;
  .view-content {
    @extend .container;
  }
  .views-accordion-header {
    background: #fff;
    border: none;
    border-radius: 30px;
    margin: 40px 0 0 0;
    padding: 20px 50px;
    position: relative;
    @include rt('medium'){

    }
    @include rt('small'){
      padding: 20px;
    }
    .field-content {
      a {
        color: #051a3b;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .ui-accordion-header-icon {
      background: url(../images/accardion_icon.png) center no-repeat;
      height: 35px;
      left: auto;
      margin: 0;
      right: 49px;
      top: 50%;
      transform: translateY(-50%);
      width: 35px;
      @include rt('medium'){
      }
      @include rt('small'){
        right: 15px;
      }
    }
    &.ui-state-active {
      background-color: #768fa5;
      border-radius: 30px 30px 0px 0px;
      .field-content {
        a {
          color: #fff;
        }
      }
      .ui-accordion-header-icon {
        background: url(../images/accardion_icon_active.png) center no-repeat;
      }
    }
  }
  .ui-accordion-content {
    background: none;
    border: none;
    padding: 5px 0 0 0;
    @include rt('medium'){
    }
    @include rt('small'){
      overflow-x:scroll;
      .views-field-field-spa-node-price{
        width: 728px;
      }
    }
  }
  .field-name-field-spa-prices-title {
    display: none;
  }
}
.spa-prices-text-pane {
  margin-bottom: 90px;
  .pane-content {
    @extend .container;
  }
}
#webform-101-pane {
  background: #fff;
  padding-bottom: 110px;
  .pane-content {
    @extend .container;
  }
}