a {
  outline: none;
  &:focus {
    outline: none;
  }
}
.node-type-front {
  .top_img {
    position: relative;
    .background-image-formatter {
      height: 1060px;
      background-size: cover;
      background-position: center;
      @include rt('medium'){
        height: 429px;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          //background-color: rgba(1,16,47,.5);
        }
      }
      @include rt('small'){
        height: 302px;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          //background-color: rgba(1,16,47,.5);
        }
      }
    }
    .top_img_text {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 335px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      text-align: center;
      color: white;
      @include rt('medium'){
        top: 97px;
      }
      @include rt('small'){
        top: 59px;
      }
      .first_row {
        font-size: 30px;
        line-height: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 24px;
        @include rt('medium'){
          font-size: 20px;
          line-height: 1;
          letter-spacing: 15px;
        }
        @include rt('small'){
          font-size: 16px;
          line-height: 1;
          letter-spacing: 12px;
        }
      }
      .second_row {
        font-size: 90px;
        line-height: 90px;
        font-weight: 800;
        @include rt('medium'){
          font-size: 60px;
          line-height: 1;
          margin-top: 10px;
        }
        @include rt('small'){
          font-size: 47px;
          line-height: 1;
        }
      }
      .third_row {
        font-size: 20px;
        line-height: 20px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 16px;
        padding-top: 18px;
        @include rt('medium'){
          font-size: 20px;
          line-height: initial;
          letter-spacing: 2px;
          padding-top: 13px;
          margin-top: 6px;
        }
        @include rt('small'){
          font-size: 11px;
          line-height: initial;
          letter-spacing: 9px;
          padding-top: 13px;
          margin-top: 3px;
        }
      }
      .book_tour {
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
        display: inline-block;
        border: 4px solid white;
        border-radius: 30px;
        margin-top: 53px;
        line-height: 52px;
        padding: 0px 72px;
        padding: 0px 42px;
        letter-spacing: 1px;
        text-decoration: none;

        @include rt('medium'){
          margin-top: 37px;
        }
        @include rt('small'){
          margin-top: 29px;
        }
      }
    }
  }
  .field-name-field-welcome-text {
    position: relative;
    @include make-container();
    margin: 0 auto;
    @include rt('medium'){
      padding-bottom: 48px;
      background: #1cabf4;
      width:100%;
    }
    @include rt('small'){
      padding-left: 0px;
      padding-right: 0px;
      min-height: 461px;
      background: #1cabf4
    }
    .field-item {
      position: absolute;
      background: url('../images/rectangle.png');
      width: 778px;
      height: 636px;
      display: block;
      top: -340px;
        left: -56px;
        color: white;
      font-size: 15px;
      font-weight: 300;
      padding: 112px 147px;
      line-height: 28px;
      @include rt('medium'){
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        background: none;
        text-align: center;
        line-height: 1.5;
        height: auto;
        padding: 66px 71px 0 71px;
      }
      @include rt('small'){
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        background: none;
        text-align: center;
        line-height: 1.5;
        height: auto;
        padding: 74px 12px 0 12px;
      }
      .top_text {
        color: white;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 36px;
        padding-bottom: 44px;
        @include rt('medium'){
          font-size: 22px;
          line-height: 1.25;
          padding-bottom: 35px;
          br{
            &:last-child{
              display: none;
            }
          }
        }
        @include rt('small'){
          font-size: 22px;
          line-height: 1.25;
          padding-bottom: 29px;
        }
      }
      p {
        padding-right: 46px;
        margin-bottom: 28px;
        @include rt('medium'){
          padding-right: 0px;
          margin-bottom: 22px;
        }
        @include rt('small'){
          padding-right: 0px;
          margin-bottom: 22px;
        }
      }
    }
  }
  .front_center_bg {
    background: url('../images/front_center_bg.jpg');
    background-size: cover;
    background-position: bottom center;
    @include rt('medium'){
      background: #01102f;
    }
    @include rt('small'){
      background: #01102f;
    }

    .front_center_subwrap_bg {
      @include make-container();
      position: relative;
      padding-bottom: 119px;
      @include rt('medium'){
        padding-bottom: 91px;
      }
      @include rt('small'){
        padding-bottom: 81px;
      }
      .paragraphs-items-field-doctor-after-welcome {
        position: relative;
        @include rt('medium'){
          min-height: 545px;
        }
        .paragraphs-item-doctor-after-preview {
          .content {
            color: #bdd9ef;
            font-size: 15px;
            font-weight: 300;
            line-height: 30px;
            display: flex;
            min-height: 614px;
            align-items: stretch;
            @include rt('medium'){
              flex-direction:column;
              align-items:flex-start;
              min-height: auto;
              text-align: left;
              padding: 64px 30px 0 30px;
              line-height: 1.5;
            }
            @include rt('small'){
              flex-direction:column;
              align-items:flex-start;
              min-height: auto;
              text-align: center;
              padding: 74px 1px 0 1px;
              line-height: 1.5;
            }
            .field-name-field-left-text {
              order:1;
              display: flex;
              width: 75%;
                align-items: center;
                padding-top: 232px;
                padding-left: 8%;
                @include rt('medium'){
                  padding: 0 0 22px 0;
                  width: 54%;
                }
                @include rt('small'){
                  padding: 0 0 22px 0;
                  width: 100%;
                }
            }
            .field-name-field-right-text {
              order:3;
              display: flex;
              width: 89%;
                align-items: center;
                padding: 0px 4%;
                @include rt('medium'){
                  width: 54%;
                  padding: 0 0 0 0;
                }
                @include rt('small'){
                  width: 100%;
                  padding: 0 0 0 0;
                }
            }
            .field-name-field-image-center {
              order:2;
              width: 374px;
              display: flex;
              align-items: flex-end;
              @include rt('medium'){
                //display: none;
                order:4;
                position: absolute;
                right: 0px;
                bottom: 0px;
              }
              @include rt('small'){
                //display: none;
                order:4;
                width: 100%;
                padding-top: 32px;
                position: relative;
                left: -12px;
                div {
                  width: 100%;
                }
                img {
                  max-width: 100%;
                  height: auto;
                  margin: 0 auto;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .view-category-front {
      @include rt('medium'){
        margin-top: 0px;
      }
      @include rt('small'){
        margin-top: 0px;
      }
      > .view-content{
        @include rt('medium'){
          flex-direction: row!important;
          flex-wrap:wrap!important;
        }
        > .views-row{
            @include rt('medium'){
              padding: 59px 34px 28px 34px;
              position: relative;
              width: 50%!important;
              min-height: 520px!important;
              margin-bottom: 0px!important;
              &:nth-child(4n+2){
                background-color: #011336;
                &:after{
                  content: '';
                  position: absolute;
                  top: 0;
                  right: -300%;
                  width: 300%;
                  height: 100%;
                  background-color: #011336;
                }
              }
              &:nth-child(4n+3){
                background-color: #011336;
                &:before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: -300%;
                  width: 300%;
                  height: 100%;
                  background-color: #011336;
                }
              }
            }
            @include rt('small'){
              padding: 41px 0 28px 0;
              position: relative;
              &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                top: 0;
                left: 50%;
                transform:translateX(-50%);
                background-color: #2dc0f6;
              }
              &:last-child{
                &:after{
                  content: '';
                  position: absolute;
                  width: 86%;
                  height: 1px;
                  bottom: 0;
                  left: 50%;
                  transform:translateX(-50%);
                  background-color: #2dc0f6;
                }
              }
            }
        }
      }
      .view-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include rt('medium'){
          flex-direction:column;
          justify-content:flex-start;
        }
        @include rt('small'){
          flex-direction:column;
          justify-content:flex-start;
        }
        .views-row {
          width: calc(50% - 1px);
          margin-bottom: 2px;
          @include rt('medium'){
            width: 100%;
          }
          @include rt('small'){
            width: 100%;
          }
          .views-field-nothing {
            .field-content {
              display: flex;
              @include rt('medium'){
                flex-direction:column;
              }
              @include rt('small'){
                flex-direction:column;
              }
              .left_part {
                width: 50%;
                  position: relative;
                  @include rt('medium'){
                    width: 100%;
                  }
                  @include rt('small'){
                    width: 100%;
                  }
                .img {
                  display: flex;
                    align-items: stretch;
                    @include rt('medium'){
                      align-items:flex-start;
                      justify-content:center;
                    }
                    @include rt('small'){
                      align-items:flex-start;
                      justify-content:center;
                    }
                    img {
                      width: 100%;
                      height: auto;
                      @include rt('medium'){
                        width: 150px;
                        height: 150px;
                        border-radius: 100%;
                        border: 4px solid #2dc0f6;
                      }
                      @include rt('small'){
                        width: 150px;
                        height: 150px;
                        border-radius: 100%;
                        border: 4px solid #2dc0f6;
                      }
                    }
                }
                .title {
                  position: absolute;
                  right: 0px;
                  bottom: 0px;
                  color: white;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 20px;
                  text-transform: uppercase;
                  width: 291px;
                  height: 131px;
                  display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 76px;
                    text-align: center;
                    padding-right: 18px;
                    padding-top: 17px;
                    @include rt('medium'){
                      position: relative;
                      width: initial;
                      height: initial;
                      padding: 24px 0 0 0;
                      color: #2dc0f6;
                      font-size: 13px;
                      font-weight: 700;
                    }
                    @include rt('small'){
                      position: relative;
                      width: initial;
                      height: initial;
                      padding: 24px 0 0 0;
                      color: #2dc0f6;
                      font-size: 13px;
                      font-weight: 700;
                    }
                }
              }
              .right_part {
                width: 50%;
                @include rt('medium'){
                  width: 100%;
                }
                @include rt('small'){
                  width: 100%;
                }
                .view-subblock-front-category {
                  position: relative;
                  display: flex;
                    height: 100%;
                    align-items: center;
                    @include rt('medium'){
                      display: block;
                      margin-top: 17px;
                    }
                    @include rt('small'){
                      display: block;
                      margin-top: 17px;
                    }
                  .view-content {
                      z-index: 2;
                      position: relative;
                      display: block;
                    padding-left: 38px;
                    padding-right: 10px;
                    @include rt('medium'){
                      padding-left: 0px;
                      padding-right: 0px;
                      display: flex;
                      flex-direction:column;
                      align-items:center;
                    }
                    @include rt('small'){
                      padding-left: 0px;
                      padding-right: 0px;
                      display: flex;
                      flex-direction:column;
                      align-items:center;
                    }
                      .views-row {
                        width: 100%;
                        padding: 5px 0px;
                      &:before {
                        content: '•';
                        margin-right: 4px;
                        color: white;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 18px;
                        display: inline-block;
                        float: left;
                      }
                        @include rt('medium'){
                          text-align: center;
                          &:before {
                            display: none;
                          }
                        }
                        @include rt('small'){
                          text-align: center;
                          &:before {
                            display: none;
                          }
                        }
                        a {
                          color: white;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 18px;
                        }
                      }
                  }
                  &:before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          &:nth-child(4n - 3) {
            .title {
              background: url('../images/tax1_left.png') bottom right;
              @include rt('medium'){
                background: none;
              }
              @include rt('small'){
                background: none;
              }
            }
            .right_part {
              .view-subblock-front-category {
                &:before {
                  background: url('../images/tax1_right.jpg') bottom right;
                  @include rt('medium'){
                    background: none;
                  }
                  @include rt('small'){
                    background: none;
                  }
                }
              }
            }
          }
          &:nth-child(4n - 2) {
            .title {
              background: url('../images/tax2_left.png') bottom right;
              @include rt('medium'){
                background: none;
              }
              @include rt('small'){
                background: none;
              }
            }
            .right_part {
              .view-subblock-front-category {
                &:before {
                  background: url('../images/tax2_right.jpg') bottom right;
                  @include rt('medium'){
                    background: none;
                  }
                  @include rt('small'){
                    background: none;
                  }
                }
              }
            }
          }
          &:nth-child(4n - 1) {
            .title {
              background: url('../images/tax3_left.png') bottom right;
              @include rt('medium'){
                background: none;
              }
              @include rt('small'){
                background: none;
              }
            }
            .right_part {
              .view-subblock-front-category {
                &:before {
                  background: url('../images/tax3_right.jpg') bottom right;
                  @include rt('medium'){
                    background: none;
                  }
                  @include rt('small'){
                    background: none;
                  }
                }
              }
            }
          }
          &:nth-child(4n) {
            .title {
              background: url('../images/tax4_left.png') bottom right;
              @include rt('medium'){
                background: none;
              }
              @include rt('small'){
                background: none;
              }
            }
            .right_part {
              .view-subblock-front-category {
                &:before {
                  background: url('../images/tax4_right.jpg') bottom right;
                  @include rt('medium'){
                    background: none;
                  }
                  @include rt('small'){
                    background: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .book_tour {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      border: 2px solid #808b9d;
      border-radius: 40px;
      display: block;
      margin: 0 auto;
      width: 300px;
      margin-top: 88px;
      line-height: 76px;
      text-align: center;
      letter-spacing: 3px;
      text-decoration: none;
      @include rt('medium'){
        width: 272px;
        margin-top: 70px;
      }
      @include rt('small'){
        width: 272px;
        margin-top: 58px;
      }
      span {
        color: #2dc0f6;
      }
    }
  }
  .paragraphs-items-field-video {
    position: relative;
    .background-image-formatter {
      height: 750px;
      background-size:cover;
      background-position: top center;
      @include rt('medium'){

      }
      @include rt('small'){
        height: 570px;
      }
    }
    .field-name-field-video-text {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content:center;
      .field-items {
        @include make-container();
        text-align: center;
        color: white;
        font-size: 30px;
        font-weight: 300;
        line-height: 70px;
        text-transform: uppercase;
        letter-spacing: 24px;
        @include rt('medium'){
          letter-spacing: 24px;
          line-height: 1;
        }
        @include rt('small'){
          font-size: 20px;
          letter-spacing: 16px;
          line-height: 1;
        }
        .youtube_text{
          @include rt('medium'){
            margin-bottom: 20px;
            line-height: 1.25;
            &:last-of-type{
              letter-spacing: 23px;
            }
          }
          @include rt('small'){
            margin-bottom: 20px;
            line-height: 1.25;
          }
        }
        .popup-youtube{
          @include rt('medium'){
            margin-bottom: 23px;
          }
          @include rt('small'){
            margin-bottom: 23px;
          }
        }
        a {
          color: white;
          font-size: 70px;
          line-height: 160px;
          text-transform: uppercase;
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: 7px;
          font-weight: 800;
          background: url('../images/play-button.png') center no-repeat;
          display: inline-block;
          outline: none;
        }
      }
    }
  }
  .paragraphs-items-field-why-we {
    @include make-container();
    padding-top: 128px;
    padding-bottom: 114px;
    @include rt('medium'){
      padding-top: 86px;
      padding-bottom: 0px;
    }
    @include rt('small'){
      padding-top: 75px;
    }
    .field-name-field-first-p-t {
      color: #ffab30;
      font-size: 30px;
      line-height: 30px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 6px;
    }
    .field-name-field-second-p-t {
      color: #01102f;
      font-size: 20px;
      line-height: 20px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 16px;
      padding-top: 3px;
    }
    .field-name-field-text-p-t {
      .field-item {
        display: flex;
        justify-content: space-between;
        padding-top: 62px;
        flex-wrap:wrap;
        .why_item {
          text-align: center;
          width: 265px;
          @include rt('medium'){
            width: 50%;
            margin-bottom: 95px;
          }
          @include rt('small'){
            width: 100%;
            margin-bottom: 44px;
          }
          .title {
            display: block;
            color: #051a3b;
            font-size: 12px;
            font-weight: 800;
            line-height: 30px;
            text-transform: uppercase;
            padding-top: 30px;
            letter-spacing: 1px;
          }
          .text {
            display: block;
            color: #051a3b;
            font-size: 15px;
            font-weight: 300;
            padding-top: 18px;
            line-height: 30px;
            @include rt('medium'){
              padding: 18px 60px 0 60px;
            }
          }
        }
      }
    }
  }
  .front_news {
    background: #edf4fa;
    padding-top: 103px;
    padding-bottom: 70px;
    @include rt('medium'){
      padding-top: 73px;
    }
    @include rt('small'){
      display: none;
    }
    .view-front-news {
      @include make-container();
      .view-header {
        text-align: center;
        color: #2dc0f6;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 6px;
      }
    }

    .view-content{
      display: flex;
      flex-direction:row;
      flex-wrap:wrap;
      align-items:flex-start;
      justify-content:space-between;
      padding-top: 27px;
      @include rt('medium'){
        padding-top: 25px;
      }
      .views-row{
        width: 363px;
        height: 600px;
        background-color: #ffffff;
        margin: 0 0 40px 0;
        position: relative;
        @include rt('medium'){
          width: 350px;
          &:last-child{
            display: none;
          }
        }
        .node-news{
          .content{
            > .fieldset {
              @include rt('medium'){
                height: 300px;
              }
            }
            .field-name-field-image{
              border-bottom: 3px solid #2dc0f6;
              img {
                height: auto;
                width: 100%;
              }
            }
            .node-title{
              margin: 55px auto 0 auto;
              text-align: center;
              padding: 0 15px;
                width: 255px;
                min-height: 42px;
              a{
                color: #051a3b;
                font-family: $OpenSans;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;
                letter-spacing: 1px;
              }
            }
            .field-name-body{
              text-align: center;
              color: #051a3b;
              font-family: $OpenSans;
              font-size: 15px;
              font-weight: 300;
              margin: 50px 0 13px 0;
              padding: 0px 60px;
              line-height: 2;
              p{
                margin: 0;
              }
            }
            .node-detail{
              // position: absolute;
              width: 100%;
              bottom: 65px;
              text-align: center;
              margin: 0px;
              @include rt('medium') {
                bottom: 0;
              }
              a{
                display: flex;
                flex-direction:row;
                align-items:center;
                justify-content:center;
                width: 220px;
                height: 40px;
                margin: 0 auto 0 auto;
                border: 1px solid rgba(5,26,59,.3);
                border-radius: 20px;
                font-family: $OpenSans;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;

                background-color: #2dc0f6;
                border-color: #2dc0f6;
                color: white;
                &:hover{
                  background-color: transparent;  
                  color: #767b88;
                  border-color: rgba(5,26,59,.3);
                }
              }
            }
          }
        }
      }
    }
    .all_news {
      width: 220px;
      height: 40px;
      background-color: white;
      border-radius: 20px;
      box-shadow: 0 4px 0 #e4eaf0;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 800;
      line-height: 30px;
      text-transform: uppercase;
      display: block;
      text-align: center;
      line-height: 40px;
      letter-spacing: 1px;
      text-decoration: none;
      color: #01102f;
      @include rt('medium'){
        margin: 10px auto 0 auto
      }
      &:hover {
        color: #2dc0f6;
      }
    }
  }
  .front_reviews {
    background: #edf4fa;
    padding-top: 61px;
    padding-bottom: 128px;
    @include rt('medium'){
      padding-top: 22px;
      padding-bottom: 124px;
    }
    .view-reviews {
      @include make-container();
      .view-header {
        text-align: center;
        color: #2dc0f6;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 6px;
        line-height: 30px;
        span {
          color: #01102f;
          font-size: 20px;
          font-weight: 300;
          line-height: 20px;
          text-transform: uppercase;
          display: block;
          letter-spacing: 16px;
          padding-top: 3px;
        }
      }
      .view-content {
        padding-top: 38px;
          display: flex;
          justify-content: space-between;
          @include rt('medium'){
            flex-direction:row;
            flex-wrap:wrap;
          }
          @include rt('small'){
            flex-direction:column;
            justify-content:flex-start;
            align-items:center;
          }
          .views-row {
            max-width: 363px;
            padding: 4px;
            background: white;
            position: relative;
            @include rt('medium'){
              width: 350px;
              &:last-child{
                display: none;
              }
            }
            img {
              width: 100%;
              height: auto;
            }
            &:hover {

              .views-field-nothing {
                display: flex;
              }
            }
            .views-field-nothing {
              display: none;
              position: absolute;
              background-color: rgba(1, 16, 47, 0.8);
              width: 100%;
              height: 100%;
              left: 0px;
              top: 0px;
              //display: flex;
              justify-content: center;
              align-items:center;
              a {
                color: white;
              font-size: 12px;
              font-weight: 700;
              line-height: 30px;
              text-transform: uppercase;
              width: 220px;
              line-height: 40px;
              background-color: #2dc0f6;
              border-radius: 20px;
              display: inline-block;
              text-decoration: none;
              text-align: center;
              letter-spacing: 1px;
              }
            }
          }
      }
    }
    .all_reviews {
      width: 220px;
      height: 40px;
      background-color: white;
      border-radius: 20px;
      box-shadow: 0 4px 0 #e4eaf0;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 800;
      line-height: 30px;
      text-transform: uppercase;
      display: block;
      text-align: center;
      line-height: 40px;
      letter-spacing: 1px;
      text-decoration: none;
      color: #01102f;
      margin-top: 42px;
      &:hover {
        color: #2dc0f6;
      }
    }
  }
  .view-direction-doctors {
    margin-top: 0px;
    @include rt('medium'){
      background-color: #edf4fa;
      margin-bottom: 0px;
      .node-doctor-doctorrs-slider{
        overflow: visible;
      }
      .slick-list{
        overflow: visible;
      }
    }
  }
}