.pagination.pager{
	padding-top: 60px;
	padding-bottom: 60px;
	font-weight: 700;
	font-size: 12px;
	.pager-next {
		a {
			text-decoration: underline;
		}
	}
	.pager-previous {
		a {
			text-decoration: underline;
		}
	}
	a{
		background-color: transparent;
		border: 0;	
		color: #051a3b;

		&:hover{
			color: #2dc0f6;
		}
	}
	.active{
		span{
			color: #2dc0f6;
			background: transparent;
			border: 0;
		}
	}
}