// Page Layout
// -----------------------------------------------------------------------------
body{
	// border: 1px solid green;
	font-weight: 300;
	font-family: "Open Sans";
	line-height: 1.8;
	-moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.container{
  @include rt('medium'){
  }
  @include rt('small'){
    padding-left: 10px;
    padding-right: 10px;
  }
}

#page-wrapper,
#all-wrapper{
	overflow: hidden;
	position: relative;
	width: 100%;
}

#all-wrapper{
  @include rt('medium'){
    transition: all 0.2s ease-in;
    &.left{
      left: 0;
    }
    &.right{
      right: 0;
    }
  }
  @include rt('small'){
    transition: all 0.2s ease-in;
    &.left{
      left: 0;
    }
    &.right{
      right: 0;
    }
  }
}

#gmap-canvas{
	height: 600px;
  @include rt('medium'){
    height: 400px;
  }
  @include rt('small'){
    height: 400px;
  }
}
.pad-85{
	padding: 0 85px;
}
.bgblue{
	background: #edf4fa;
}
#header{
	// position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
}
.media-icon {
	display: none !important;
}
// .admin-menu{
// 	#header{
// 		top: 30px;
// 	}
// }

#modalContent {
  overflow: auto;
	left: 0 !important;
	max-width: 100%;
	width: 100%;
/* Other overrides for top, etc */
}
.ctools-modal-content {
 	max-width: 100%;
}
.modal-dialog {
 	width: 100%!important;
}
#modal-content {
 	&>div {
 		@include make-container();
 	}
}
.modal-header {
	@include make-container();
	padding: 0px;
	position: relative;
	h4 {
		display: none;
	}
	button {
		position: absolute;
		right: 10px;
		top: 122px;
		width: 21px;
		height: 21px;
		background: url('../images/popup_close.jpg');
		opacity: 1;
	    display: block;
	    z-index: 10;
	    text-indent: -9999px;
	    outline: none;
    @include rt('medium'){
      top: 60px;
    }
	}
}
#modalContent {
	.alert {
		display: none;
	}
}
#modalContent {
#webform-ajax-wrapper-366 {
    padding: 115px 0 123px 0;
    @include rt('medium'){
      padding: 60px 0 60px 0;
    }
    .webform-client-form{
      > div{
        @include clearfix();
        > .form-item{
          margin: 0 0 40px 0;
          @include rt('medium'){
            margin: 0 0 20px 0;
          }
        }
      }
    }
    .webform-component--zagolovok{
      text-align: center;
      line-height: 1;
      margin: 0 0 47px 0!important;
      .t1{
        color: #ffab30;
        font-family: $OpenSans;
        font-size: 30px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 7px;
      }
      .t2{
        color: #01102f;
        font-family: $OpenSans;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 16px;
        margin: 3px 0 0 0px;
      }
    }
    select,
    input[type="text"],
    input[type="email"]{
      width: 363px;
      height: 60px;
      @include rt('medium'){
        width: 100%;
      }
    }
    textarea{
      width: 100%;
      height: 120px;
      resize:none;
      border: none;
      box-shadow: none;
    }
    .form-number-col {
      @include rt('medium'){
        &.first {
          padding-right: 10px;
        }
        &.second {
          padding-left: 10px;
        }
      }
    }

    .webform-component--tip-lica,
    .webform-component--e-mail,
    .webform-component--fio,
    .webform-component--data-nachala,
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      width: calc(100% / 3);
      float: left;
      @include rt('medium'){
        width: 50%;
      }
    }
    .webform-component--e-mail,
    .webform-component--fio,
    .webform-component--data-okonchaniya {
      @include rt('medium'){
        padding-left: 10px;
      }
    }
    .webform-component--data-nachala,
    .webform-component--kontaktnyy-telefon,
    .webform-component--tip-lica {
      @include rt('medium'){
        padding-right: 10px;
      }
    }

    .webform-component--fio,
    .webform-component--data-nachala{
      input{
        margin-left: auto;
        margin-right: auto;
      }
    }
    .webform-component--kontaktnyy-telefon,
    .webform-component--data-okonchaniya{
      input{
        margin-left: auto;
      }
    }
    .webform-component--dopolnitelnye-pozhelaniya{
      clear: both;
    }

    .webform-component--liqpay-i-rekvizity{
      float: right;
      display: flex;
      flex-direction:row;
      margin: 0 249px 0 0!important;
      @include rt('medium'){
        float: left;
        margin: 0 !important;
      }
      .liqpay{
        a{
          display: block;
          margin: 0 39px 0 0;
          padding: 14px 0 0 0;
          letter-spacing: 1px;
          width: 240px;
          height: 80px;
          border-radius: 40px;
          box-shadow: 0 4px 0 #d8dee3;
          text-align: center;
          background: #edf4fa url('../images/conference/liqpay.jpg') bottom 17px center no-repeat;
          color: #71af2a;
          font-family: $OpenSans;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          @include rt('medium'){
            margin-right: 50px;
          }
        }
      }
      .requisites{
        height: 80px;
        display: flex;
        flex-direction:row;
        align-items:center;
        a{
          color: #ffab30;
          font-family: $OpenSans;
          font-size: 11px;
          font-weight: 700;
          text-decoration: underline;
          text-transform: uppercase;
        }
      }
    }

    .form-actions {
      margin: 0 0 0 246px;
      float: left;
      @include rt('medium'){
        float: right;
        margin: 0;
      }
      input[type="submit"]{
        border:0;
        width: 240px;
        height: 80px;
        padding: 0;
        background-color: #ffab30;
        border-radius: 40px;
        box-shadow: 0 4px 0 #e0982b;
        color: white;
        font-family: $OpenSans;
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    .form-number-container {
      background-color: #edf4fa;
      border-radius: 30px;
      height: 60px;
      margin-bottom: 40px;
      padding: 10px 55px 0 40px;
      position: relative;
      width: 363px;
      @include rt('medium'){
        margin-bottom: 20px;
        width: 100%;
      }
      .form-item {
        margin: 0;
      }
      .form-required {
        color: #051a3b;
      }
      label {
        color: #051a3b;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: 300;
        float: left;
        line-height: 40px;
      }
      input[type=text] {
        background: #fff;
        border-radius: 10px;
        box-shadow: inset 0.7px 0.7px 0 rgba(0, 0, 0, 0.12);
        color: #051a3b;
        display: inline-block;
        font-size: 15px;
        font-weight: 300;
        float: right;
        height: 40px;
        padding: 0;
        text-align: center;
        width: 40px;
      }
      .number-actions {
        height: 36px;
        position: absolute;
        right: 30px;
        top: 13px;
        width: 15px;
        .inc,
        .dec {
          background-color: #01102f;
          border-radius: 50%;
          color: #fff;
          cursor: pointer;
          height: 15px;
          font-size: 20px;
          line-height: 15px;
          text-align: center;
          user-select: none;
          width: 15px;
          &:hover {
            background-color: #ffab30;
          }
        }
        .inc {
          margin-bottom: 6px;
        }
        .dec {
          line-height: 12px;
        }
      }
    }

  }
}

.node-type-page {
	h1 {
		color: #ffab30;
		font-size: 30px;
		font-weight: 300;
		text-transform: uppercase;
		border-bottom: 3px solid #edf4fa;
		font-family: "Open Sans";
		padding-top: 63px;
		letter-spacing: 6px;
		padding-bottom: 15px;
		margin-bottom: 61px;
		@include make-container();
	}
	.field-name-body {
		color: #051a3b;
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
		padding-bottom: 75px;
		strong {
			font-size: 15px;
		}
		p {
			margin-bottom: 16px;
		}
		img {
			max-width: 100%;
			height: auto;
			margin: 0 auto;
			display: block;
		}
	}
}

body.node-type-our-achievements{
  .field-name-field-text-our-achievements{
    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 2px;
      li {
        font-size: 16px;
        font-weight: 400;
        &:before {
          content: '•';
          color: #2dc0f6;
          padding-right: 4px;
        }
      }
    }
  }
} 

.page-questions-and-answers{
  #main{
    .field-name-field-answer{
      ul {
        list-style: none;
        padding-left: 0px;
        padding-top: 2px;
        li {
          font-size: 16px;
          font-weight: 400;
          &:before {
            content: '•';
            color: #2dc0f6;
            padding-right: 4px;
          }
        }
      }
    }
  } 
} 

.node-type-spa{
  .field-name-field-text-after-video{
    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 2px;
      li {
        font-size: 16px;
        font-weight: 400;
        &:before {
          content: '•';
          color: #2dc0f6;
          padding-right: 4px;
        }
      }
    }
  }
} 

.node-type-disease{
  .field-name-body{
    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 2px;
      li {
        font-size: 16px;
        font-weight: 400;
        &:before {
          content: '•';
          color: #2dc0f6;
          padding-right: 4px;
        }
      }
    }
  }
} 

.node-type-deriction{
  .field-name-field-body-3-screen{
    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 2px;
      li {
        font-size: 16px;
        font-weight: 400;
        &:before {
          content: '•';
          color: #2dc0f6;
          padding-right: 4px;
        }
      }
    }
  }
} 