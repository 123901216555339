body.node-type-about-us {
	.top_img_text {
		position: relative;
		.field-name-field-image {
			.background-image-formatter {
				height:510px;
				background-size: cover;
				background-position: center;
			}
		}
		.text {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			h1 {
				@include make-container();
				color: #ffab30;
				font-size: 30px;
				font-weight: 300;
				text-transform: uppercase;
				text-align: center;
				font-family: "Open Sans";
				padding-top: 168px;
				letter-spacing: 6px;
				@include rt('medium'){
					padding-top: 140px;
				}
				@include rt('small'){
					padding-top: 115px;
					font-size: 24px;
				}
			}
			.field-name-field-text-after-title {
				@include make-container();
				color: white;
				font-size: 20px;
				font-weight: 300;
				line-height: 30px;
				text-align: center;
				padding: 0px 100px;
				padding-top: 14px;
				@include rt('medium'){
				}
				@include rt('small'){
					font-size: 16px;
				}
			}
		}
	}
	.field-name-body {
		@include make-container();
		color: #051a3b;
		font-size: 15px;
		line-height: 28px;
		font-weight: 300;
		padding-top: 82px;
		padding-bottom: 58px;
		p {
			margin-bottom: 15px;
			margin-bottom: 28px;
		}
		.quote {
			&:before, &:after {
				width: 80px;
				height: 10px;
				background: #2dc0f6;
				display: inline-block;
				content: '';
				@include rt('medium'){
					width: 63px;
				}
				@include rt('small'){
					width: 46px;
					height: 8px;
				}
			}
			&:before{
				margin-right: 33px;
				@include rt('medium'){
					margin-right: 27px;
				}
				@include rt('small'){
					margin-right: 15px;
				}
			}
			&:after {
				margin-left: 33px;
				@include rt('medium'){
					margin-left: 27px;
				}
				@include rt('small'){
					margin-left: 15px;
				}
			}
			padding-top: 23px;
			padding-bottom: 52px;
			color: #5a8fa2;
			font-family: Georgia;
			font-size: 30px;
			font-style: italic;
			line-height: 32px;
			@include rt('medium'){

			}
			@include rt('small'){
				font-size: 23px;
				line-height: 25px;
				padding-top: 5px;
				padding-bottom: 28px;
			}
		}
	}
	.img-gallery{
		background: #edf4fa;
		.paragraphs-items-field-mc-gallery{
			> .field-name-field-mc-gallery{
				> .field-items{
					@include rt('medium'){
						height: 583px !important;
						margin: 0 auto;
						width: 583px;
						.field-item  {
							padding: 3px;
						    width: 50%;
						    box-sizing: border-box;
						    img {
						    	max-width: 100%;
						    	height: auto;		
						    }		
						    &:nth-child(2),		    
						    &:nth-child(3) {
						    	img {
						    		max-height: 282px;
						    	}
						    }		    
						}
					}
					&.slick-slider{
						padding: 28px;
					}
					.slick-track{
						display: flex;
						flex-direction:row;
						align-items:center;
						img{
							max-width: 100%;
							height: auto;
							margin: auto;
						}
					}
					.slick-slide{
						height: initial;
					}
					.slick-prev{
						left: 0;
						background: url(../images/slider_arrows_prev.png) 0 0 no-repeat;
						@include rt('small'){
							left: -10px;
						}
					}
					.slick-next{
						right: 0;
						background: url(../images/slider_arrows_next.png) 0 0 no-repeat;
						@include rt('small'){
							right: -10px;
						}
					}
					.slick-arrow{
						position: absolute;
						top: 50%;
						transform:translateY(-50%);
						border: none;
						height: 29px;
						margin-right: 6px;
						outline: none;
						text-indent: -99999px;
						width: 28px;
						&:hover{
							background-position: 0 -29px;
						}
					}
					> .field-item{
						float: left;
						margin-bottom: 1px;
						@include rt('medium'){
							&:nth-child(n+4) {
								display: none;
							}
						}
						.paragraphs-item-pb-image-image-style{
							a{
								display: block;
								position: relative;
								&:hover{
									&:before{
										content: '';
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										background: rgba(45,192,246,.9) url('../images/search.png') center no-repeat;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.field-name-field-text-after-gallery {
		@include make-container();
		padding-top: 82px;
		padding-bottom: 58px;
		color: #051a3b;
		font-size: 15px;
		font-weight: 300;
		line-height: 28px;
		.bottom_title {
			color: #ffab30;
			font-size: 18px;
			font-weight: 700;
			line-height: 28px;
			text-transform: uppercase;
			letter-spacing: 1px;
			padding-bottom: 42px;
			@include rt('medium'){
			}
			@include rt('small'){
				font-size: 16px;
				line-height: 26px;
			}
		}
		ul {
			padding: 0px;
			margin: 0px;
			text-align: center;
			padding-bottom: 41px;
			li {
				background: url('../images/about_li.png') top 6px left no-repeat;
				padding-left: 15px;
				color: #051a3b;
				font-size: 18px;
				font-weight: 300;
				line-height: 28px;
				text-transform: uppercase;
				display: inline;
				letter-spacing: 1px;
    			margin: 0px 47px;
    			@include rt('medium'){
    				list-style: none;
    				display: block;
    				margin: 0px 0px 7px 0px;
    			}
    			@include rt('small'){
    				list-style: none;
    				display: block;
    				margin: 0px 0px 7px 0px;
    				font-size: 16px;
    				line-height: 26px;
    			}
			}
		}
		p {
			margin-bottom: 28px;
		}
	}
	.pane-node-field-text-bottom {
		color: #051a3b;
		font-size: 15px;
		line-height: 28px;
		font-weight: 300;
		background: #edf4fa;
		padding-top: 84px;
		padding-bottom: 58px;
		&>.pane-content {
			@include make-container();
		}
		p {
			margin-bottom: 28px;
		}
	}
}